import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { DataContext } from '../../../context/DataContext';

import { Box, Button, IconButton, TextField, Typography } from "@mui/material";

import ProductElement from "./content/ProductElement";
import ProdTags from "./content/ProdTags";
import ProdSort from "./content/ProdSort";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const ProductsList = () => {
  const {user, setMsg} = useContext(DataContext);

  const [stock, setStock] = useState([]);

  const [products, setProducts] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", order: "" });
  const [activeTags, setActiveTags] = useState([]);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getStock();
  }, []);

  const getStock = async() => {
    const res = await fetch(apiUrl+"/product/stock/company/"+user.company.id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setStock(data.stock);

    const productGroup = data.stock.reduce((group, x) => {
      (group[x["product_id"]] = group[x["product_id"]] || []).push(x);
      return group;
    }, {});

    const allArr = [];

    Object.keys(productGroup).map(key => {
      const sum = productGroup[key].reduce((acc, obj) => {
        return acc + obj.quantity;
      }, 0);

      return allArr.push({
        id: productGroup[key][0].id,
        barcode: productGroup[key][0].barcode,
        articleNum: productGroup[key][0].article_num,
        fastCode: productGroup[key][0].fastcode,
        priceType: productGroup[key][0].price_type,
        name: productGroup[key][0].name,
        product: productGroup[key][0].product,
        product_id: productGroup[key][0].product_id,
        vat: Number(productGroup[key][0].vat)*100,
        unit: productGroup[key][0].unit,
        unit_quantity: productGroup[key][0].unit_quantity ? productGroup[key][0].unit_quantity : 0,
        image: productGroup[key][0].image,
        quantity: sum
      });
    });

    console.log(allArr)

    setProducts(allArr);

    /*if(defaultBarcode) {
      getProductPage(data.stock.filter(item => item.barcode === defaultBarcode)[0]);
    }*/
  };

  const handleSort = (key, order) => {
    setSortConfig({ key, order });

    const sortedProducts = [...products].sort((a, b) => {
      let valueA, valueB;
      if (key === "margin") {
        valueA = ((a.eladArBr - a.beszArBr) / a.beszArBr) * 100;
        valueB = ((b.eladArBr - b.beszArBr) / b.beszArBr) * 100;
      } else if (key === "name") {
        valueA = a.name.toLowerCase();
        valueB = b.name.toLowerCase();
      } else if (key === "price") {
        valueA = a.eladArBr;
        valueB = b.eladArBr;
      }

      if (order === "asc") {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueA < valueB ? 1 : -1;
      }
    });

    setProducts(sortedProducts);
  };

  const filteredProducts = products.filter((product) => {
    const matchesSearch =
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.ean.includes(searchTerm);
    const matchesTags =
      activeTags.length === 0 || activeTags.every((tagId) => product.tags.includes(tagId));
    return matchesSearch && matchesTags;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "1300px",
        margin: "0 auto",
        boxSizing: "border-box",
        mt: 10,
        padding: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, width: '100%', maxWidth: '1300px' }}>
        <IconButton onClick={() => navigate('/tobacco')}><ArrowBackIcon /></IconButton>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c'}}>Vissza a főoldalra</Typography>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 2 }}>
        <Typography sx={{
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontWeight: '600',
          color: '#08979C',
        }}>Termékek kezelése</Typography>
        <Button sx={{ color: "#08979C", gap: 1, borderRadius: 1 }}>
          <Typography sx={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: '300',
            color: '#888',
          }}>Új termék létrehozása</Typography>
          <AddCircleIcon />
        </Button>
      </Box>

      {/* Keresőmező */}
      <TextField
        fullWidth
        size="small"
        label="Keresés a termékek között"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Rendezési gombok */}
      <ProdSort sortConfig={sortConfig} handleSort={handleSort} />

      {/* Termékek listája */}
      {filteredProducts.map((product) => (
        <ProductElement
          key={product.id}
          product={product}
          stock={stock.filter(item => item.barcode === product.barcode)}
          onRefresh={getStock}
        />
      ))}
    </Box>
  );
};

export default ProductsList;