import { useState, useRef, useEffect } from 'react';

import {
  Box,
  Grid2,
  IconButton,
  Typography,
  Modal,
} from '@mui/material';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { io } from 'socket.io-client';

const socket = io('http://192.168.1.51:5000');

const BillsAttachedFile = ({ onFileChange }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreviewOpen, setFilePreviewOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const fileInputRef = useRef(null);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Kapcsolódva a mock szerverhez.');
    });

    socket.on('filesUpdated', (updatedFiles) => {
      setUploadedFiles(updatedFiles);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => file.size <= 10 * 1024 * 1024); // Max 10 MB
    const fileObjects = validFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      isImage: file.type.startsWith('image/'),
    }));
    const updatedFiles = [...uploadedFiles, ...fileObjects];
    setUploadedFiles(updatedFiles);

    socket.emit('updateFiles', updatedFiles);

    if (onFileChange) {
      onFileChange(updatedFiles.length);
    }
  };

  const handleDeleteFile = (index) => {
    setUploadedFiles((prev) => {
      const fileToDelete = prev[index];
      URL.revokeObjectURL(fileToDelete.url);
      const updatedFiles = prev.filter((_, i) => i !== index);

      socket.emit('updateFiles', updatedFiles);

      if (onFileChange) {
        onFileChange(updatedFiles.length);
      }
      return updatedFiles;
    });
  };

  const handleOpenFilePreview = (index) => {
    setCurrentFileIndex(index);
    setFilePreviewOpen(true);
  };

  const handleCloseFilePreview = () => {
    setFilePreviewOpen(false);
  };

  const handlePrevFile = () => {
    setCurrentFileIndex((prevIndex) =>
      prevIndex === 0 ? uploadedFiles.length - 1 : prevIndex - 1
    );
  };

  const handleNextFile = () => {
    setCurrentFileIndex((prevIndex) =>
      prevIndex === uploadedFiles.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid2 container spacing={1}>
        {/* Fájl feltöltési opciók */}
        <Grid2 size={{ xs: 6, md: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              border: '1px dashed #08979C',
              borderRadius: 2,
              height: '150px',
              cursor: 'pointer',
              '&:hover': { borderColor: '#68C9CC' },
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <AddPhotoAlternateOutlinedIcon sx={{ fontSize: '40px', color: '#08979C' }} />
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', color: '#08979C', fontWeight: '500' }}>
              Fájl csatolása
            </Typography>
          </Box>
          <input
            type="file"
            hidden
            ref={fileInputRef}
            multiple
            accept="image/*,application/pdf"
            onChange={handleFileUpload}
          />
        </Grid2>

        {/* Feltöltött fájlok megjelenítése */}
        {uploadedFiles.map((fileObject, index) => (
          <Grid2 size={{ xs: 6, md: 2 }} key={index}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #08979C',
                borderRadius: 2,
                height: '150px',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
              onClick={() => handleOpenFilePreview(index)}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFile(index);
                }}
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  bgcolor: '#000',
                  opacity: 0.3,
                  color: '#FFF',
                  width: 24,
                  height: 24,
                  '&:hover': { bgcolor: '#000', opacity: 0.7 },
                }}
              >
                <DeleteOutlinedIcon sx={{ fontSize: '16px' }} />
              </IconButton>
              {fileObject.isImage ? (
                <img
                  src={fileObject.url}
                  alt={fileObject.file.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <InsertDriveFileOutlinedIcon
                    sx={{
                      fontSize: '40px',
                      color: '#08979C',
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      fontWeight: 600,
                      textAlign: 'center',
                      mt: 1,
                      color: '#08979C',
                    }}
                  >
                    {fileObject.file.name.split('.').pop().toUpperCase()}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid2>
        ))}
      </Grid2>

      {/* Modal a fájlok előnézetére */}
      <Modal
  open={filePreviewOpen}
  onClose={handleCloseFilePreview}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  <Box
    sx={{
      position: 'relative',
      width: uploadedFiles[currentFileIndex]?.isImage ? 'auto' : '90%',
      height: uploadedFiles[currentFileIndex]?.isImage ? 'auto' : '90%',
      maxWidth: '90%',
      maxHeight: '90%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center', // Kép középre igazítása
      justifyContent: 'center', // Kép középre igazítása
    }}
  >
    <IconButton
      onClick={handlePrevFile}
      sx={{
        position: 'absolute',
        left: 10,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        bgcolor: '#fff',
        '&:hover': { bgcolor: '#f0f0f0' },
      }}
    >
      <ArrowBackIosNewIcon />
    </IconButton>

    {uploadedFiles[currentFileIndex]?.isImage ? (
      <img
        src={uploadedFiles[currentFileIndex]?.url}
        alt="Preview"
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
          objectFit: 'contain', // Arányok megtartása
        }}
      />
    ) : (
      <iframe
        src={uploadedFiles[currentFileIndex]?.url}
        title="Preview"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    )}

    <IconButton
      onClick={handleNextFile}
      sx={{
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        bgcolor: '#fff',
        '&:hover': { bgcolor: '#f0f0f0' },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  </Box>
</Modal>
    </Box>
  );
};

export default BillsAttachedFile;