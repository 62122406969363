import { createContext, useRef, useState } from "react";

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

export const DataContext = createContext();

export const StateProvider = ({ children }) => {
    const [user, setUser] = useState("");
    const [ops, setOps] = useState([]);
    const [stores, setStores] = useState([]);

    const [msg, setMsg] = useState({});
    const [loading, setLoading] = useState(false);

    const [icons] = useState([
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />,
        <PointOfSaleIcon />
    ]);

    const [scroll, setScroll] = useState(0);
    const scrollRef = useRef();

    const value = {
        user,
        setUser,
        ops,
        setOps,
        stores,
        setStores,
        msg, setMsg,
        loading, setLoading,
        icons,
        scroll,
        setScroll,
        scrollRef
    };

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};