// Termékadatok
export const testProducts = [
    // Üdítők
    { id: 1, name: "Coca-Cola 0.33l dobozos", ean: "1234567890123", itemNumber: "", shortCode: "CC33", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 200, beszArBr: 254, eladArNet: 300, eladArBr: 381, package: 24, tags: [1] },
    { id: 2, name: "Fanta narancs 1.5l PET", ean: "2345678901234", itemNumber: "", shortCode: "FN15", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 350, beszArBr: 445, eladArNet: 500, eladArBr: 635, package: 6, tags: [1] },
    { id: 3, name: "Sprite 1.5l PET", ean: "3456789012345", itemNumber: "", shortCode: "SP15", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 340, beszArBr: 431.8, eladArNet: 480, eladArBr: 610, package: 6, tags: [1] },
    // Szeszes italok
    { id: 4, name: "Jack Daniels 0.7l", ean: "4567890123456", itemNumber: "", shortCode: "JD07", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 8000, beszArBr: 10160, eladArNet: 9500, eladArBr: 12065, package: 6, tags: [2] },
    { id: 5, name: "Johnnie Walker Red Label 0.7l", ean: "5678901234567", itemNumber: "", shortCode: "JWR07", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 7500, beszArBr: 9525, eladArNet: 9000, eladArBr: 11430, package: 6, tags: [2] },
    // Csokik
    { id: 6, name: "Milka tejcsokoládé 100g", ean: "6789012345678", itemNumber: "", shortCode: "MILKA", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 300, beszArBr: 381, eladArNet: 400, eladArBr: 508, package: 12, tags: [3] },
    { id: 7, name: "Boci étcsokoládé 90g", ean: "7890123456789", itemNumber: "", shortCode: "BOCI", unit: "1", unitType: "db", type: "open", vat: 27, beszArNet: 250, beszArBr: 317.5, eladArNet: 350, eladArBr: 450, package: 12, tags: [3] },
    // Dohánytermékek
    { id: 8, name: "Marlboro Gold 20", ean: "8901234567890", itemNumber: "A1234567", shortCode: "", unit: "1", unitType: "csomag", type: "close", vat: 0, beszArNet: 1400, beszArBr: 1400, eladArNet: 1600, eladArBr: 1600, package: 10, tags: [4] },
    { id: 9, name: "Camel Blue 20", ean: "9012345678901", itemNumber: "A1234568", shortCode: "", unit: "1", unitType: "csomag", type: "close", vat: 0, beszArNet: 1350, beszArBr: 1350, eladArNet: 1550, eladArBr: 1550, package: 10, tags: [4] },
    // Újságok
    { id: 10, name: "Napi Gazdaság", ean: "0123456789012", itemNumber: "", shortCode: "GAZD", unit: "1", unitType: "db", type: "open", vat: 5, beszArNet: 500, beszArBr: 525, eladArNet: 700, eladArBr: 735, package: 1, tags: [5] },
    { id: 11, name: "Blikk", ean: "1234567890133", itemNumber: "", shortCode: "BLIK", unit: "1", unitType: "db", type: "open", vat: 5, beszArNet: 400, beszArBr: 420, eladArNet: 550, eladArBr: 577.5, package: 1, tags: [5] },
  ];
  
  // Címkék
  export const initialProdTags = [
    { id: 1, name: "Üdítők", color: "#08979C" },
    { id: 2, name: "Szeszes italok", color: "#AA0000" },
    { id: 3, name: "Csokoládék", color: "#8855AA" },
    { id: 4, name: "Dohánytermékek", color: "#444444" },
    { id: 5, name: "Újságok", color: "#FFD700" },
  ];

  // Telephelyek adatai
export const locations = [
  { id: 1, name: "Csabai út", color: "#08979C" },
  { id: 2, name: "Dózsa utca", color: "#FF5733" },
  { id: 3, name: "BSZT", color: "#FFC107" },
  { id: 4, name: "Vasút utca", color: "#6C757D" },
  { id: 5, name: "NSZA", color: "#6C757D" },
];

// Készletek
export const stockData = [
  { productId: 1, locationId: 1, quantity: 50 },
  { productId: 1, locationId: 2, quantity: 30 },  
  { productId: 1, locationId: 3, quantity: 0 },
  { productId: 1, locationId: 4, quantity: 0 },
  { productId: 1, locationId: 5, quantity: 0 },
  { productId: 2, locationId: 1, quantity: 20 },
  { productId: 2, locationId: 3, quantity: 40 },
  { productId: 3, locationId: 1, quantity: 15 },
  { productId: 4, locationId: 4, quantity: 5 },
  { productId: 5, locationId: 2, quantity: 10 },
];

// Beszerzési adatok
export const incomingData = [
  {
    productId: 1,
    locationId: 1,
    supplier: "Coca-Cola Hungary Kft.",
    invoiceNumber: "CC-INV-20250101",
    date: "2025-01-01",
    receivedBy: "Kovács János",
    purchasePriceBrutto: 254,
    purchasePriceNetto: 200,
    quantity: 50,
  },
  {
    productId: 1,
    locationId: 2,
    supplier: "Coca-Cola Hungary Kft.",
    invoiceNumber: "CC-INV-20250103",
    date: "2025-01-03",
    receivedBy: "Nagy Ádám",
    purchasePriceBrutto: 260,
    purchasePriceNetto: 205,
    quantity: 20,
  },
  {
    productId: 2,
    locationId: 3,
    supplier: "PepsiCo Hungary Kft.",
    invoiceNumber: "PEPSI-INV-20250102",
    date: "2025-01-02",
    receivedBy: "Szabó Tímea",
    purchasePriceBrutto: 445,
    purchasePriceNetto: 350,
    quantity: 12,
  },
  {
    productId: 3,
    locationId: 1,
    supplier: "Sprite Hungary Kft.",
    invoiceNumber: "SPRITE-INV-20250104",
    date: "2025-01-04",
    receivedBy: "Kiss László",
    purchasePriceBrutto: 431.8,
    purchasePriceNetto: 340,
  },
];

// Forgalmi adatok
export const salesData = [
  { productId: 1, date: "2023-12-01", soldQuantity: 20 },
  { productId: 1, date: "2023-12-02", soldQuantity: 15 },
  { productId: 1, date: "2023-12-03", soldQuantity: 25 },
  { productId: 1, date: "2023-12-04", soldQuantity: 18 },
  { productId: 1, date: "2023-12-05", soldQuantity: 30 },
  { productId: 2, date: "2023-12-01", soldQuantity: 10 },
  { productId: 2, date: "2023-12-02", soldQuantity: 8 },
  { productId: 2, date: "2023-12-03", soldQuantity: 12 },
  { productId: 2, date: "2023-12-04", soldQuantity: 9 },
  { productId: 2, date: "2023-12-05", soldQuantity: 14 },
];