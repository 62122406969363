import { Box, Typography, Button } from '@mui/material';

const NoResults = ({ searchTerm, onCreateNew }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
    <Typography sx={{ textAlign: 'center', color: '#8C8C8C', mt: 2 }}>
      Nincs találat a következőre:
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        color: '#8c8c8c',
        fontWeight: '600',
      }}
    >
      "{searchTerm}"
    </Box>
    <Button
      variant="contained"
      onClick={onCreateNew}
      sx={{
        mt: 2,
        justifyContent: 'flex-start',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '300',
        color: '#FFF',
        bgcolor: '#68C9CC',
        '&:hover': { bgcolor: '#08979C' },
      }}
    >
      Új költséghely létrehozása
    </Button>
  </Box>
);

export default NoResults;