import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Box, Chip, Menu, MenuItem, TextField, Button, Typography } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from '@mui/icons-material/Clear';

const ProdTags = ({ product }) => {
  const {user, setMsg} = useContext(DataContext);

  const [tags, setTags] = useState([]); // Helyi tag állapot
  const [tagList, setTagList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null); // Menü horgony
  const [searchTerm, setSearchTerm] = useState(""); // Keresési kifejezés

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getTagList();
    getTags();
  }, [product]);

  const getTagList = async() => {
    const res = await fetch(apiUrl+"/producttags/"+user.company.id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setTagList(data.tags);
  };

  const getTags = async() => {
    const res = await fetch(apiUrl+"/product/tags/"+product.product_id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setTags(data.tags);
  };

  // Menü nyitása/zárása
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Tag hozzáadása a meglévők közül
  const handleAddTag = async(tagId) => {
    if(tags.filter(item => item.id === tagId).length !== 0) return;

    const res = await fetch(apiUrl+"/producttags/set", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        barcode: product.barcode,
        company: user.company.id,
        tag: tagId,
        user: user.id
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    getTags();

    handleMenuClose();
  };

  // Új tag hozzáadása
  const handleAddNewTag = async() => {
    const res = await fetch(apiUrl+"/producttags/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: searchTerm,
        colorCode: "#BFBFBF",
        company: user.company.id
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setSearchTerm("");

    getTagList();
    handleAddTag(data.id);
  };

  // Tag törlése
  const handleDeleteTag = async(tagId) => {
    const res = await fetch(apiUrl+"/producttags/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        product: product.product_id,
        tag: tagId
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    getTags();
  };

  // Szűrés a keresési kifejezés alapján
  const filteredTags = tagList.filter(
    (tag) => !tags.includes(tag.id) && tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, flexWrap: "wrap" }}>
      
      {/* Tag hozzáadás gomb */}
      <Button onClick={handleMenuOpen} sx={{ color: "#08979C", gap: 1, borderRadius: 1 }}>
        <Typography sx={{
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: '300',
          color: '#888',
        }}>Címke hozzáadás</Typography>
        <AddCircleIcon />
      </Button>

      {/* Meglévő tag-ek */}
      {tags.map((tag) => (
        <Chip
          key={tag.id}
          label={tag.name}
          size="small"
          sx={{
            backgroundColor: tag.color_code,
            color: "#fff",
            fontSize: "10px",
            borderRadius: 1,
            padding: 0.3,
            "& .MuiChip-deleteIcon": { // Törlés ikon színének beállítása
            color: "#fff",
            },
          }}
          onDelete={() => handleDeleteTag(tag.id)} // Törlés esemény
          deleteIcon={<ClearIcon sx={{ color: "#fff" }} />}
        />
      ))}

      {/* Tag hozzáadás menü */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Tag keresése vagy hozzáadása"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Keresési mező frissítése
            sx={{ mb: 1 }}
          />
          {filteredTags.length > 0 ? (
            filteredTags.map((tag) => (
              <MenuItem key={tag.id} onClick={() => handleAddTag(tag.id)}>
                {tag.name}
              </MenuItem>
            ))
          ) : (
            <Box sx={{ textAlign: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Nincs találat. Hozzáadjam?
              </Typography>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAddNewTag}
                disabled={!searchTerm.trim()} // A keresési mező üres-e
              >
                Új tag hozzáadása
              </Button>
            </Box>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

export default ProdTags;