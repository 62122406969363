import React, { useContext, useState } from 'react';

import useDebounce from '../../../../hooks/useDebounce';
import { DataContext } from '../../../../context/DataContext';
import { getUserCompaniesStores, getUserStores } from '../../../../services/Api';

import { Box } from '@mui/system';
import ShopElement from './content/ShopElement';
import { TextField, Accordion, AccordionSummary, AccordionDetails, Typography, Grid2, IconButton, Button, Grow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import AddNewShop from './content/AddNewShop';

const ShopList = () => {
  const {user, setUser, stores, setStores, setMsg, setLoading} = useContext(DataContext);

  const [filteredStores, setFilteredStores] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [isAddingShop, setIsAddingShop] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useDebounce(() => {
    try {
      const rx = new RegExp(`${searchTerm}`,'i');

      setFilteredStores(stores.filter(item => rx.test(item.name) || rx.test(item.address)));
    }
    catch(err) {
        setSearchTerm("");
        setMsg({
            color: "error",
            message: "Erre a karakterre nem lehet keresni"
        });
    }
  }, [stores, searchTerm], 500);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleAddShopToggle = () => {
    setIsAddingShop(!isAddingShop);
  };

  const handleAddShop = async(newShop) => {
    setLoading(true);

    const res = await fetch(apiUrl+"/store/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user.id,
        company: user.company.id,
        store: {
          name: newShop.name,
          address: newShop.address,
          color_code_2: newShop.color,
          initials: newShop.initials
        }
      })
    });

    const data = await res.json();

    if(!data.success) {
        setLoading(false);
        return setMsg({
            color: "error",
            message: data.message
        });
    }

    getUserStores(user.id).then(userStores => {
        setUser(prev => ({...prev, stores: userStores}));

        getUserCompaniesStores(user.id).then(storeData => {
            setStores(storeData);

            setMsg({
                color: "success",
                message: "Sikeresen létrehoztad a telephelyet"
            });

            setIsAddingShop(false);
            setLoading(false);
        });
    });
  };

  const handleCancelAddShop = () => {
    setIsAddingShop(false);
  };

  const handleOpen = (shopName) => {
    alert(`Napnyitás: ${shopName}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container>
        <Accordion
          defaultExpanded
          sx={{
            maxWidth: '100%',
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="eladoter-content"
            id="eladoter-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontWeight: 'bold', color: '#08979C' }}>Eladótér</Typography>
              {!isAddingShop && (
                <Grow in timeout={500}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative',
                    '&:hover .hover-text': {
                      opacity: 1,
                      transform: 'translateX(0)',
                    },
                  }}
                >
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAddShopToggle();
                    }}
                    sx={{
                      width: 16,
                      height: 16,
                      bgcolor: '#08979C',
                      color: '#fff',
                      borderRadius: 1,
                      '&:hover': { bgcolor: '#68C9CC' },
                    }}
                  >
                    <AddIcon sx={{ fontSize: 12 }} />
                  </IconButton>
                  <Typography
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAddShopToggle();
                    }}
                    className="hover-text"
                    sx={{
                      opacity: 0,
                      transform: 'translateX(-10px)',
                      transition: 'opacity 0.3s ease, transform 0.3s ease',
                      fontSize: '12px',
                      color: '#08979C',
                      cursor: 'pointer',
                    }}
                  >
                    Új eladótér hozzáadása
                  </Typography>
                </Box>
                </Grow>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxWidth: '100%',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Box sx={{ maxWidth: '100%', width: '100%' }}>
              {isAddingShop ? (
                <AddNewShop onAddShop={handleAddShop} onCancel={handleCancelAddShop} />
              ) : (
                <Grid2 container spacing={0.5}>
                  <Grid2 size={12}>
                    <TextField
                      size="small"
                      label="Keresés"
                      variant="outlined"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      sx={{
                        marginBottom: 1,
                        width: '100%',
                        '& .MuiInputBase-root': {
                          fontSize: '14px',
                          color: '#8c8c8c',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '12px',
                          color: '#8c8c8c',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#08979C',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#d9d9d9',
                          },
                          '&:hover fieldset': {
                            borderColor: '#08979C',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#68C9CC',
                          },
                        },
                      }}
                    />
                  </Grid2>

                  {filteredStores.map((shop, index) => (
                    <Grid2 size={12} key={index}>
                      <ShopElement
                        id={shop.id}
                        name={shop.name}
                        address={shop.address}
                        initials={shop.name[0]}
                        color={shop.color_code_2}
                        viewState={'dailyopen'} // Átadjuk az adott shop állapotát
                        onOpen={() => handleOpen(shop.name)}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              )}
<Grid2 sx={12} padding={1}>
  {!isAddingShop && ( // Csak akkor jelenik meg, ha nem az AddNewShop nézet van
    <Button                     
      onClick={(event) => {
        event.stopPropagation();
        handleAddShopToggle();
      }} 
      size='small' 
      sx={{ 
        width: '100%', 
        fontSize: '10px', 
        fontWeight: '300', 
        color: '#8c8c8c', 
        '&:hover': {
          backgroundColor: '#08979C',
          color: '#fff',
        }, 
      }} 
    >
      Új eladótér létrehozása
    </Button>
  )}
</Grid2>
            </Box>
            
          </AccordionDetails>
        </Accordion>
      </Grid2>
    </Box>
  );
};

export default ShopList;