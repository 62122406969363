import { useEffect, useState } from "react";

import { Button, Grid2, TextField } from '@mui/material';

const ProdBaseInfoNew = ({ product, onUpdateProduct, onSaveProduct }) => {
  const [editedProduct, setEditedProduct] = useState({});
  
  useEffect(() => {
    setEditedProduct(product);
  }, [product]);

  return (
    <Grid2 container spacing={3}>
      {/* Vonalkód_2 input */}
      <Grid2 size={8}>
        <TextField
          label="Vonalkód"
          focused
          variant="standard"
          size="small"
          fullWidth
          value={editedProduct.barcode}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, barcode: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      {/* Gyorskód input */}
      <Grid2 size={4}>
        <TextField
          label="Gyorskód"
          focused
          variant="standard"
          size="small"
          fullWidth
          value={editedProduct.fastCode}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, fastCode: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      {/* Megnevezés */}
      <Grid2 size={12}>
        <TextField
          label="Megnevezés"
          focused
          variant="standard"
          size="small"
          fullWidth
          value={editedProduct.product}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, product: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      {/* Áfa */}
      <Grid2 size={6}>
        <TextField
          label="Áfa"
          focused
          variant="standard"
          size="small"
          fullWidth
          value={editedProduct.vat}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, vat: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      {/* Vonalkód_2 input */}
      <Grid2 size={6}>
        <TextField
          label="Cikkszám"
          focused
          variant="standard"
          size="small"
          fullWidth
          value={editedProduct.articleNum}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, articleNum: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      {/* Kiszerelési egység input */}
      <Grid2 size={6}>
        <TextField
          label="Eladási mennyiség"
          variant="standard"
          focused
          size="small"
          fullWidth
          value={editedProduct.unit_quantity}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, unit_quantity: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      {/* kiszerelési mennyiség */}
      <Grid2 size={6}>
        <TextField
          label="Eladási egység"
          variant="standard"
          focused
          size="small"
          fullWidth
          value={editedProduct.unit}
          onChange={(e) => {
            const updatedProduct = { ...editedProduct, unit: e.target.value };
            setEditedProduct(updatedProduct); // Állapot frissítése
            onUpdateProduct(updatedProduct); // Frissítés a fő komponensben is
          }}
          sx={{
            cursor: "pointer",
            // Egyedi stílusok
            "& .MuiInputBase-input": {
              color: "#08979C", // Szöveg színe
              fontSize: "14px", // Betűméret
              fontWeight: "500", // Betűvastagság
            },
            "& .MuiInputLabel-root": {
              color: "#888", // Címke színe
              fontSize: "12px", // Címke betűméret
            },
            "& .MuiInput-underline:before": {
              borderBottom: "1px solid #ccc", // Alapértelmezett szegély
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "2px solid #08979C", // Hover szegély
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #08979C", // Fókusz szegély
            },
            "& .Mui-disabled": {
              color: "#aaa", // Disabled állapot színe
            },
          }}
        />
      </Grid2>

      <Grid2 size={12}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#08979C",
          }}
          onClick={onSaveProduct}
        >
          Mentés
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default ProdBaseInfoNew;