import { useState, useEffect, useContext } from 'react';

import { io } from "socket.io-client";

import { DataContext } from '../../../../context/DataContext';

import {
  Box,
  Typography,
  IconButton,
  Drawer,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const NewCostPlace = ({ bill, open, onClose, onRefresh, costCategories, searchTerm }) => {
  const {user, setMsg} = useContext(DataContext);

  const [newName, setNewName] = useState('');
  const [newColor, setNewColor] = useState('#68C9CC');
  const [newGroupName, setNewGroupName] = useState('');

  const [selectedGroup, setSelectedGroup] = useState('');

  const [isNewGroup, setIsNewGroup] = useState(false);

  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if(open) {
      setNewName(searchTerm);
    }
  }, [open, searchTerm]);

  const handleGroupSelection = (group) => {
    setSelectedGroup(group);
    setIsNewGroup(false);
  };

  const handleNewGroupSelection = () => {
    setSelectedGroup('');
    setIsNewGroup(true);
  };

  const handleSave = async() => {
    if(!newName.trim() || (!isNewGroup && !selectedGroup)) {
      return alert('Kérjük, töltsön ki minden mezőt!');
    }
  
    if(isNewGroup && (!newGroupName.trim() || !newColor)) {
      return alert('Kérjük, adjon meg egy új csoport nevet és színt!');
    }
  
    if(isNewGroup) {
      const res = await fetch(apiUrl+"/costplace/add/newgroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bill: bill.id,
          groupName: newGroupName,
          company: user.company.id,
          color: newColor,
          name: newName,
          user: user.id
        })
      });

      const data = await res.json();

      if(!data.success) {
        console.log(data.message)
        return setMsg({
          color: "error",
          message: data.message
        });
      }

      socket.emit("modify_costplace");

      resetFields();
      onClose();
    }
    else {
      const res = await fetch(apiUrl+"/costplace/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bill: bill.id,
          name: newName,
          group: selectedGroup,
          company: user.company.id,
          user: user.id
        })
      });

      const data = await res.json();

      if(!data.success) {
        console.log(data.message);
        return setMsg({
          color: "error",
          message: data.message
        });
      }

      socket.emit("modify_costplace");

      resetFields();
      onRefresh();
      onClose();
    }
  };

  const resetFields = () => {
    setNewName('');
    setNewColor('#68C9CC');
    setSelectedGroup('');
    setNewGroupName('');
    setIsNewGroup(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: '400px' },
        }}
      >
        <Box sx={{ p: 3, bgcolor: 'background.paper', height: '100%', overflowY: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography sx={{ fontWeight: '600', color: '#08979C' }}>
              Új költséghely létrehozása
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
            <TextField
              fullWidth
              label="Költséghely neve"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              variant="outlined"
              size="small"
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: '600', mb: 1, color: '#8c8c8c' }}>
              Válasszon csoportot a költséghelyhez
            </Typography>
            <FormGroup>
              {costCategories.map((category) => (
                <FormControlLabel
                  key={category.groupId}
                  control={
                    <Checkbox
                      sx={{
                        color: '#8c8c8c',
                        '&.Mui-checked': { color: '#08979C' },
                        '& .MuiSvgIcon-root': { borderRadius: '50%' },
                      }}
                      checked={selectedGroup === category.groupId}
                      onChange={() => handleGroupSelection(category.groupId)}
                    />
                  }
                  label={category.groupName}
                  sx={{ color: '#8c8c8c' }}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNewGroup}
                    onChange={handleNewGroupSelection}
                    sx={{
                      color: '#8c8c8c',
                      '&.Mui-checked': { color: '#08979C' },
                      '& .MuiSvgIcon-root': { borderRadius: '50%' },
                    }}
                  />
                }
                label="Új csoport hozzáadása"
                sx={{ color: '#8c8c8c' }}
              />
            </FormGroup>
            {isNewGroup && (
              <>
                <TextField
                  fullWidth
                  label="Új csoport neve"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Új csoport színe"
                  type="color"
                  value={newColor}
                  onChange={(e) => setNewColor(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 2, cursor: 'pointer' }}
                />
              </>
            )}
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSave}
            sx={{
              justifyContent: 'center',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '300',
              color: '#FFF',
              bgcolor: '#68C9CC',
              '&:hover': { bgcolor: '#08979C' },
            }}
          >
            Mentés és hozzáadás
          </Button>
        </Box>
      </Drawer>
      <Modal
        open={colorPickerOpen}
        onClose={() => setColorPickerOpen(false)}
        aria-labelledby="color-picker-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: '8px',
            boxShadow: 24,
            textAlign: 'center',
          }}
        >
          <input
            type="color"
            value={newColor}
            onChange={(e) => setNewColor(e.target.value)}
            style={{
              width: '200px',
              height: '200px',
              border: 'none',
              cursor: 'pointer',
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default NewCostPlace;