import { Box, Grid2, Skeleton, Tooltip, Typography } from '@mui/material';

import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

import CurrencyText from '../../../../components/CurrencyText';

const BillsMainContent = ({ bill }) => {
  return (
    <Grid2 size={12}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              {bill.partner_name ?
                  bill.partner_name.length > 35 ? (
                    <Tooltip title={bill.partner_name} arrow>
                      <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8C8C8C' }}>
                        {bill.partner_name.slice(0, 35)}...
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8C8C8C' }}>
                      {bill.partner_name}
                    </Typography>
                  )
                :
                  <Skeleton variant="rounded" width={120} height={20} />
              }
              {bill.comment && (
                <Tooltip title={bill.comment} arrow>
                  <CommentOutlinedIcon sx={{ fontSize: '16px', color: '#8C8C8C' }} />
                </Tooltip>
              )}
            </Box>
            {bill.serial_num ?
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8C8C8C' }}>
                  {bill.serial_num}
                </Typography>
              :
                <Skeleton variant="rounded" width={80} height={15} />
            }
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          {bill.sum ?
              <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8C8C8C' }}>
                <CurrencyText val={bill.sum}/> Ft
              </Typography>
            :
              <Skeleton variant="rounded" width={110} height={20} />
          }

          {bill.sum ?
              <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8C8C8C' }}>
                <CurrencyText val={bill.sum}/> Ft
              </Typography>
            :
              <Skeleton variant="rounded" width={80} height={15} />
          }
        </Box>
      </Box>
    </Grid2>
  );
};

export default BillsMainContent;