import { Box, Grid2, Skeleton, Typography } from '@mui/material';

import BillsSalaryDate from './BillsSalaryDate';
import BillsSalaryType from './BillsSalaryType';
import AddShopAvatar from './AddShopAvatar';

const BillsDatesAndType = ({ bill, onRefresh }) => {
  return (
    <Grid2 size={{ xs: 12, md: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          {bill.record_date ?
              <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8C8C8C' }}>
                {bill.record_date}
              </Typography>
            :
              <Skeleton variant="rounded" width={70} height={15} />
          }

          <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8C8C8C' }}>
            Kelte
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {bill.delivery_date ?
              <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8C8C8C' }}>
                {bill.delivery_date}
              </Typography>
            :
              <Skeleton variant="rounded" width={70} height={15} />
          }

          <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8C8C8C' }}>
            Teljesítés
          </Typography>
        </Box>
        {!bill.deadline_nav ? (
          <BillsSalaryDate
            bill={bill}
            onRefresh={onRefresh}
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {bill.payment_deadline ?
              <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8C8C8C' }}>
                {bill.payment_deadline}
              </Typography>
            :
              <Skeleton variant="rounded" width={70} height={15} />
            }

            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8C8C8C' }}>
              Lejárat
            </Typography>
          </Box>
        )}
        {!bill.payment_method ? (
          <BillsSalaryType
            bill={bill}
            onRefresh={onRefresh}
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {bill.payment_method ?
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8C8C8C' }}>
                  {bill.payment_id === 1 ? "Készpénz" : "Banki átutalás"}
                </Typography>
              :
                <Skeleton variant="rounded" width={70} height={15} />
            }

            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8C8C8C' }}>
              Fizetési mód
            </Typography>
          </Box>
        )}
      </Box>
    </Grid2>
  );
};

export default BillsDatesAndType;