import { useContext, useState } from 'react';

import { DataContext } from '../../../../context/DataContext';

import { Box, Typography, TextField, IconButton } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const BillsComment = ({ billId, existingComment, onRefresh }) => {
  const {setMsg} = useContext(DataContext);

  const [newComment, setNewComment] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleAddComment = async() => {
    if(newComment.trim()) {
      const res = await fetch(apiUrl+"/inbound/comment/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bill: billId,
          comment: newComment
        })
      });

      const data = await res.json();

      if(!data.success) {
        console.log(data.message)
        return setMsg({
          color: "error",
          message: data.message
        });
      }

      setNewComment('');
      onRefresh();
    }
  };

  const handleDeleteComment = async() => {
    const res = await fetch(apiUrl+"/inbound/comment/delete/"+billId, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    onRefresh();
  };

  return (
    <Box>
      {existingComment ? (
        <Box
          sx={{
            display: 'flex',
            width: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#08979C',
            borderRadius: 1,
            p: 1,
            mt: 1,
          }}
        >
          <Typography sx={{ fontSize: '12px', color: '#FFF' }}>{existingComment}</Typography>
          <IconButton size="small" onClick={handleDeleteComment}>
            <DeleteOutlinedIcon sx={{ fontSize: '16px', color: '#FFF' }} />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 1,
            width: '100%',
          }}
        >
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Megjegyzés hozzáadása a számlához"
            value={newComment}
            multiline // Többsoros beviteli mező
            rows={4} // Kezdeti sorok száma
            onChange={(e) => setNewComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Enter csak hozzáadja a megjegyzést, nem ugrik új sorba
                handleAddComment();
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d9d9d9',
                },
                '&:hover fieldset': {
                  borderColor: '#08979C',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#08979C',
                },
              },
            }}
          />
          <IconButton
            size="small"
            onClick={handleAddComment}
            sx={{
              bgcolor: '#08979C',
              color: '#FFF',
              '&:hover': {
                bgcolor: '#68C9CC',
              },
            }}
          >
            <AddIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default BillsComment;