import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import { DataContext } from '../../../context/DataContext';

import { Box, IconButton, Pagination, TextField, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import BillsListElement from './content/BillsListElement';

import { io } from "socket.io-client";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const BillsList = () => {
  const {user, setMsg, scroll, setScroll, scrollRef} = useContext(DataContext);

  const [loading, setLoading] = useState(false);

  const [costplaces, setCostplaces] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [showInvoiceData, setShowInvoiceData] = useState([]);

  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);

  const location = useLocation();

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if(location.state) {
      setPage(location.state.page);
    }
    
    setInvoiceData([{},{},{},{},{},{},{},{},{},{},{},{}]);
    setShowInvoiceData([{},{},{},{},{},{},{},{},{},{},{},{}]);
  }, []);

  useEffect(() => {
    if(showInvoiceData) {
      // Ellenőrizni, hogy a DOM renderelve lett
      const raf = requestAnimationFrame(() => {
        scrollRef.current.scrollTop = scroll;
      });

      return () => cancelAnimationFrame(raf);
    }
  }, [showInvoiceData]);

  useEffect(() => {
    getCostplaces();
    getNewInvoices();
  }, [user]);

  useEffect(() => {
    socket.on("update_costplace", () => {
      getCostplaces();
    });
  }, [socket]);

  useDebounce(() => {
    try {
      const normalizeString = (str) =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Ékezetek eltávolítása

      const normalizedSearch = normalizeString(search);
      const rx = new RegExp(`${normalizedSearch}`, 'i');

      const filteredData = invoiceData.filter(item => 
        rx.test(normalizeString(item.partner_name)) || 
        rx.test(normalizeString(item.serial_num))
      );

      setPages(Math.round(filteredData.length / 12));
      setShowInvoiceData(filteredData);
    }
    catch (err) {
      setSearch("");
      setMsg({
        color: "error",
        message: "Erre a karakterre nem lehet keresni"
      });
    }
  }, [invoiceData, search], 500);

  const getCostplaces = async() => {
    const res = await fetch(apiUrl+"/inbound/costplaces/"+user.company.id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setCostplaces(data.costplaces);
  };

  const getNewInvoices = async() => {
    setLoading(true);

    const res = await fetch(apiUrl+"/inbound/newinvoices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ token: user.company.nav_token, company: user.company.id })
    });

    const data = await res.json();

    if(!data.success){
      getInvoices();
      return setMsg({
        color: data.noNew ? "success" : "error",
        message: data.message
      });
    }

    if(data.invoices.length !== 0) {
      return syncPartners(data.invoices);
    }

    getInvoices();
  };

  const syncPartners = async(invoices) => {
    const res = await fetch(apiUrl+"/inbound/sync/partners", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(invoices)
    });

    const data = await res.json();

    if(!data.success){
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    syncInvoices(invoices);
  };

  const syncInvoices = async(invoices) => {
    const res = await fetch(apiUrl+"/inbound/sync/invoices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        company: user.company.id,
        token: user.company.nav_token,
        invoices: invoices
      })
    });

    const data = await res.json();

    if(!data.success){
      setMsg({
        color: "error",
        message: data.message
      });
    }

    getInvoices();
  };

  const getInvoices = async() => {
    const res = await fetch(apiUrl+"/inbound/get/all/"+user.company.id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success){
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setPages(Math.round(data.invoices.length/12));

    if(location.state) {
      setInvoiceData(data.invoices.map(item => {
        if(item.id === location.state.bill) {
          return {
            ...item,
            open: true
          } 
        }
  
        return {
          ...item,
          open: false
        }
      }));
    }
    else {
      setInvoiceData(data.invoices.map(item => {
        return {
          ...item,
          open: false
        }
      }));
    }

    setLoading(false);
  };

  const getInvoice = async(serialNum) => {
    const res = await fetch(apiUrl+"/inbound/get/serial", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ serialNum: serialNum })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setInvoiceData(prev => {
      const index = prev.findIndex(item => item.serial_num === data.invoice.serial_num);

      return [
        ...prev.slice(0, index),
        data.invoice,
        ...prev.slice(index+1)
      ];
    });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleOpenAccordion = (bill) => {
    setShowInvoiceData(prev => prev.map(item => {
      if(item.id === bill.id) {
        return {
          ...item,
          open: !item.open
        };
      }

      return {
        ...item,
        open: false
      };
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        mt: 8,
        padding: 1,
        mb: 4,
        height: '100vh',
        overflow: 'auto'
      }}
      ref={scrollRef}
      onScroll={(e) => setScroll(scrollRef.current.scrollTop)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, width: '100%', maxWidth: '1200px' }}>
        <IconButton onClick={() => navigate('/tobacco')}><ArrowBackIcon /></IconButton>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c'}}>Vissza a főoldalra</Typography>
      </Box>

      <TextField
        placeholder="Keresés"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        sx={{ width: "95vw", maxWidth: "1200px" }}
      />

      {!loading &&
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            maxWidth="1200px"
          >
            <Pagination
              count={pages}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={page}
              onChange={handleChangePage}
            />
          </Box>

          {showInvoiceData.filter((_, i) => i >= (page-1)*12 && i < ((page-1)*12)+12).map((bill) => (
            <BillsListElement
              key={bill.id}
              bill={bill}
              costplaces={costplaces}
              page={page}
              onRefresh={() => {
                getCostplaces();
                getInvoice(bill.serial_num);
              }}
              onAccordion={() => handleOpenAccordion(bill)}
            />
          ))}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            maxWidth="1200px"
          >
            <Pagination
              count={pages}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        </>
      }
    </Box>
  );
};

export default BillsList;