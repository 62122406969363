import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { salesData } from "../data/mockData";

const ProdSalaryChart = ({ productId }) => {
  // Szűrés az adott termékre
  const productSales = salesData.filter((data) => data.productId === productId);

  // Adatok előkészítése a diagramhoz
  const chartData = productSales.map((data) => ({
    date: data.date,
    quantity: data.soldQuantity,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        mt: 2,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: "800",
          color: "#08979C",
          mb: 2,
        }}
      >
        Forgalmi adatok napokra bontva
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }} padding={0}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} />
          <YAxis tick={{ fontSize: 12 }} />
          <Tooltip
            contentStyle={{ fontSize: "12px" }}
            formatter={(value) => [`${value} db`, "Mennyiség"]}
          />
          <Bar dataKey="quantity" fill="#08979C" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ProdSalaryChart;