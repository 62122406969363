import { useState, useEffect, useContext } from 'react';

import { io } from "socket.io-client";

import { DataContext } from '../../../../context/DataContext';

import {
  Box,
  Typography,
  IconButton,
  Drawer,
  TextField,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const ModifyCostCategory = ({ open, onRefresh, onClose, selectedCategory }) => {
  const {setMsg} = useContext(DataContext);

  const [categoryName, setCategoryName] = useState('');
  const [categoryColor, setCategoryColor] = useState('#68C9CC');

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (open && selectedCategory) {
      setCategoryName(selectedCategory.groupName || '');
      setCategoryColor(selectedCategory.color || '#68C9CC');
    }
  }, [open, selectedCategory]);

  const handleSave = async() => {
    if (!categoryName.trim()) {
      alert('Kérjük, adjon meg egy érvényes csoportnevet!');
      return;
    }

    const res = await fetch(apiUrl+"/costplace/group/modify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: selectedCategory.groupId,
        name: categoryName,
        color: categoryColor
      })
    });

    const data = await res.json();

    if(!data.success) {
      console.log(data.message)
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    socket.emit("modify_costplace_category");
    
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: '400px' },
      }}
    >
      <Box sx={{ p: 3, bgcolor: 'background.paper', height: '100%', overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography sx={{ fontWeight: '600', color: '#08979C' }}>
            Csoport módosítása
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          label="Csoport neve"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Csoport színe"
          type="color"
          value={categoryColor}
          onChange={(e) => setCategoryColor(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ mb: 2, cursor: 'pointer' }}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handleSave}
          sx={{
            justifyContent: 'center',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: '300',
            color: '#FFF',
            bgcolor: '#68C9CC',
            '&:hover': { bgcolor: '#08979C' },
          }}
        >
          Mentés
        </Button>
      </Box>
    </Drawer>
  );
};

export default ModifyCostCategory;