import { useContext, useState } from 'react';

import { DataContext } from '../../../../context/DataContext';

import { Box, Typography, IconButton, Modal } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const BillsSalaryDate = ({ bill, onRefresh }) => {
  const {setMsg} = useContext(DataContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSave = async(newDate) => {
    const res = await fetch(apiUrl+"/inbound/set", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "deadline",
        newData: newDate,
        invoice: bill
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setModalOpen(false);
    setPickerOpen(false);

    onRefresh();
  };

  const handleAddDateClick = () => {
    setModalOpen(true);
    setPickerOpen(true); // Nyissa meg automatikusan a naptárat
  };

  const handleClearDate = async() => {
    const res = await fetch(apiUrl+"/inbound/tag/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "deadline",
        invoice: bill
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    onRefresh();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setPickerOpen(false); // Zárja be a naptárat
  };

  return (
    <>
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          mt: 1.5,
          mb: 1.5,
          '&:hover .delete-icon': {
            display: { xs: 'none', md: 'flex' }, // Telefonos nézetben rejtett
          },
        }}
      >
        {!bill.payment_deadline ? (
          <Box
            onClick={handleAddDateClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px dashed #08979C',
              borderRadius: 1,
              padding: 0.4,
              cursor: 'pointer',
              transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
              '&:hover': {
                borderColor: '#68C9CC',
                border: '1px solid #08979C',
                backgroundColor: '#CBEEEF',
                transform: 'scale(1.1)', // Méret növelése hoovereléskor
              },
            }}
          >
            <AddIcon sx={{ fontSize: '14px', color: '#08979C' }} />
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#08979C', pr: 1 }}>
              Lejárat
            </Typography>
          </Box>
        ) : (
          <Box
            onClick={handleAddDateClick} // A meglévő dátummal nyitja meg a modalt
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              alignItems: 'flex-start',
              cursor: 'pointer',
            }}
          >
            {/* Dátum megjelenítése */}
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: '500',
                color: '#08979C',
              }}
            >
              {bill.payment_deadline ? bill.payment_deadline : ''}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: '300',
                color: '#08979C',
              }}
            >
              Fizetési határidő
            </Typography>

            {/* Badge ikon */}
            <IconButton
              className="delete-icon"
              onClick={(e) => {
                e.stopPropagation(); // Megakadályozza a modal megnyitását
                handleClearDate();
              }}
              sx={{
                position: 'absolute',
                top: -10,
                right: -5,
                bgcolor: '#FF5722',
                color: '#FFF',
                width: 16,
                height: 16,
                fontSize: '12px',
                borderRadius: '50%',
                display: 'none', // Alapértelmezés szerint rejtett
                transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
                '&:hover': {
                  backgroundColor: '#E53935',
                  transform: 'scale(1.2)'}, // Méret növelése hoovereléskor
              }}
            >
              <CloseIcon sx={{ fontSize: '12px' }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Modal for adding date */}
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: '8px',
            boxShadow: 24,
            width: '300px',
            textAlign: 'center',
          }}
        >
          <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500', color: '#08979C', mb: 2 }}>
            Lejárati dátum hozzáadása
          </Typography>
          <DatePicker
            open={pickerOpen}
            onClose={() => setPickerOpen(false)} // Bezárja a naptárat, ha kiválasztottunk egy dátumot
            value={bill.payment_deadline} // A meglévő dátum értéke megjelenik
            onChange={(newValue) => handleSave(newValue)}
            renderInput={(params) => <Box sx={{ display: 'none' }} {...params} />}
          />
        </Box>
      </Modal>
    </>
  );
};

export default BillsSalaryDate;