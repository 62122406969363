import { Accordion, AccordionDetails, AccordionSummary, Badge, Typography, Box } from '@mui/material';

import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import BillsAttachedFile from './BillsAttachedFile';

const FileAttachedAccordionMenu = ({ billId, fileCount, openSubAccordion, handleSubAccordionChange, onFileChange }) => {
    
  return (
    <Accordion
      expanded={openSubAccordion[billId] === 'files'}
      onChange={() => handleSubAccordionChange(billId, 'files')}
      sx={{ boxShadow: 'none' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <AttachFileOutlinedIcon sx={{ fontSize: '18px', color: '#8c8c8c' }} />
          <Badge
            badgeContent={fileCount}
            color="primary"
            invisible={fileCount === 0}
            sx={{
              '& .MuiBadge-badge': {
                right: -6,
                bgcolor: '#FF5722',
                color: '#FFF',
                fontSize: '7px',
                fontWeight: '400',
                minWidth: '16px',
                height: '16px',
                borderRadius: '50%',
              },
            }}
          >
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8c8c8c' }}>
              Fájlok csatolása
            </Typography>
          </Badge>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <BillsAttachedFile onFileChange={onFileChange} />
      </AccordionDetails>
    </Accordion>
  );
};

export default FileAttachedAccordionMenu;