import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Tooltip,
  Typography,
} from "@mui/material";

const ProdIncomingInfo = ({ productId }) => {
  const {user, setMsg} = useContext(DataContext);

  const [purchases, setPurchases] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getPurchases();
  }, [productId]);

  const getPurchases = async() => {
    const res = await fetch(apiUrl+"/product/purchases", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        company: user.company.id,
        product: productId
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
          color: "error",
          message: data.message
      });
    }

    setPurchases(data.purchases);
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", color: "#08979C" }}>Telephely</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#08979C" }}>Beszállító</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#08979C" }}>Bevételi személy</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#08979C" }}>Beszerzési ár</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#08979C" }}>Mennyiség</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchases.map((incoming) => {
            return (
              <TableRow key={incoming.id}>
                {/* Telephely oszlop */}
                <TableCell>
                  <Tooltip title={incoming.storeName} arrow>
                    <Avatar
                      sx={{
                        bgcolor: incoming.color_code_2,
                        width: 32,
                        height: 32,
                        fontSize: "14px",
                        fontWeight: "600",
                        mr: 1,
                      }}
                    >
                      {incoming.storeName[0]}
                    </Avatar>
                  </Tooltip>
                </TableCell>

                {/* Beszállító és számlaszám */}
                <TableCell>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {incoming.name}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#888" }}>
                    Számla: {incoming.invoice_num}
                  </Typography>
                </TableCell>

                {/* Bevételi személy és dátum */}
                <TableCell>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {incoming.last_name+" "+incoming.first_name}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#888" }}>
                    {incoming.date}
                  </Typography>
                </TableCell>

                {/* Beszerzési ár bruttó és nettó */}
                <TableCell>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#333" }}>
                    {incoming.purchase_price.toFixed(2)} Ft
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#888" }}>
                    Nettó: {(incoming.purchase_price / (1 + incoming.vat / 100)).toFixed(2)} Ft
                  </Typography>
                </TableCell>

                {/* Mennyiség */}
                <TableCell>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#333" }}>
                    {incoming.quantity} db
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProdIncomingInfo;