import { Grid2, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

const BillsMenus = ({ bill, details, page, onPrintClick }) => {
  const navigate = useNavigate();

  return (
    <Grid2 container size={12} spacing={0.5}>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <Grid2 container spacing={0.5}>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              onClick={() => navigate('/bills/details', { state: {bill: bill, page: page} })} // Navigáció az útvonalra
              disabled={details}
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#68C9CC', // Halványabb háttérszín disabled állapotban
                  color: '#FFF', // Halványabb szövegszín
                  opacity: 0.6, // Átlátszóság beállítása disabled állapotban
                },
              }}
            >
              <AssignmentOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Számla tételek
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              onClick={onPrintClick} // PDF generálás hívása
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
              }}
            >
              <LocalPrintshopOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Nyomtatás
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
              }}
            >
              <AssignmentTurnedInOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Bevételezés
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
              }}
            >
              <AssuredWorkloadOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Utalás
              </Typography>
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <Grid2 container spacing={0.5}>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
              }}
            >
              <HistoryOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Számla történet
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
              }}
            >
              <BarChartOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Kimutatás
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
              }}
            >
              <IosShareOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Megosztás
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 3 }}>
            <Button
            disabled
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#08979c',
                borderRadius: 1,
                padding: 1,
                cursor: 'pointer',
                transition: 'transform 0.2s ease, background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: '#68C9CC',
                  transform: 'scale(0.95)',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#68C9CC', // Halványabb háttérszín disabled állapotban
                    color: '#FFF', // Halványabb szövegszín
                    opacity: 0.6, // Átlátszóság beállítása disabled állapotban
                  },
              }}
            >
              <ReportOutlinedIcon sx={{ color: '#FFF' }} />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontWeight: '300',
                  color: '#FFF',
                }}
              >
                Riport, jelentés
              </Typography>
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default BillsMenus;