import { Typography, Box, Chip } from "@mui/material";

const ProdHeadLine = ({ product }) => {
  const marginPercentage = Math.round(((product.sellPrice / product.purchasePrice) - 1) * 100);
  const marginColor = (() => {
    if (marginPercentage < 10) {
      return "#FF4D4F"; // Piros
    } else if (marginPercentage >= 10 && marginPercentage < 15) {
      return "#FF7A7A"; // Halvány piros
    } else if (marginPercentage >= 15 && marginPercentage < 20) {
      return "#FFA500"; // Narancssárga
    } else if (marginPercentage >= 20 && marginPercentage < 25) {
      return "#FFC069"; // Halvány narancssárga
    } else if (marginPercentage >= 25 && marginPercentage < 35) {
      return "#9ACD32"; // Halvány zöld
    } else {
      return "#08979C"; // Zöld
    }
  })();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: 2,
        padding: 1,
      }}
    >
      {/* Termék neve és vonalkód */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: marginColor,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          {marginPercentage + "%"}
        </Box>
        <Box>
          <Typography sx={{ fontFamily: "Roboto", fontSize: "14px", fontWeight: "500", color: "#595959" }}>
            {product.product}
          </Typography>
          <Typography sx={{ fontFamily: "Roboto", fontSize: "12px", color: "#8c8c8c" }}>
            {product.barcode}
          </Typography>
        </Box>
      </Box>

      {/* Árak */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Chip
          label={`${product.sellPrice} Ft`}
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "4px",
            backgroundColor: marginColor,
            color: "#fff",
          }}
        />
      </Box>
    </Box>
  );
};

export default ProdHeadLine;