import { useContext, useEffect, useState } from 'react';

import { io } from "socket.io-client";

import { DataContext } from "../../../../context/DataContext";

import { Avatar, Typography, Box, Modal, Grid2, IconButton, Checkbox, Button } from '@mui/material';

import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const AddShopAvatar = ({ bill }) => {
  const {stores, setMsg} = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const [selectedShops, setSelectedShops] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setSelectedShops(bill.stores);
  }, [bill.stores]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggleShop = (shop) => {
    setSelectedShops((prev) =>
      prev.some((s) => s.id === shop.id)
        ? prev.filter((s) => s.id !== shop.id)
        : [...prev, shop]
    );
  };

  const handleClearSelection = async() => {
    const res = await fetch(apiUrl+"/inbound/tag/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "stores",
        invoice: bill
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    socket.emit("bill_stores");

    handleClose();
  };

  const handleSave = async() => {
    const res = await fetch(apiUrl+"/inbound/set", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "stores",
        invoice: bill,
        newData: selectedShops.map(item => item.id)
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    socket.emit("bill_stores");

    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'flex-start',
          gap: 1,
          cursor: 'pointer',
          '&:hover .delete-icon': {
            display: { xs: 'none', md: 'flex' }, // Törlés ikon telefonos nézetben rejtve
          },
        }}
        onClick={handleOpen} // Modal megnyitása avatarra kattintva
      >
        {bill?.stores.map((shop, index) => (
          <Avatar
            key={shop.id}
            sx={{
              bgcolor: shop.color_code_2,
              color: '#FFF',
              width: 28,
              height: 28,
              fontSize: '10px',
              position: index === 0 ? 'relative' : 'absolute',
              left: index === 0 ? '0' : `${index * -20}px`,
              border: '1px solid white',
              zIndex: bill?.stores.length - index,
              transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
              '&:hover': {
                transform: 'scale(1.08)'}, // Méret növelése hoovereléskor
            }}
          >
            {shop.initials}
          </Avatar>
        ))}
        {bill?.stores.length > 0 && (
          <IconButton
            className="delete-icon"
            onClick={(e) => {
              e.stopPropagation(); // Ne nyissa meg a modalt törléskor
              handleClearSelection();
            }}
            sx={{
              position: 'absolute',
              zIndex: 1000,
              top: '-5px',
              right: '-8px',
              bgcolor: '#FF5722',
              color: '#FFF',
              width: 16,
              height: 16,
              display: 'none', // Alapértelmezés szerint rejtett
              transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
              '&:hover': {
                backgroundColor: '#E53935',
                transform: 'scale(1.2)'}, // Méret növelése hoovereléskor
            }}
          >
            <CloseOutlinedIcon sx={{ fontSize: '12px' }} />
          </IconButton>
        )}
        {bill?.stores.length === 0 && (
          <Avatar
            sx={{
              bgcolor: 'transparent',
              color: '#08979C',
              border: '1px dashed #08979C',
              width: 28,
              height: 28,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
              '&:hover': {
                borderColor: '#68C9CC',
                border: '1px solid #08979C',
                backgroundColor: '#CBEEEF',
                transform: 'scale(1.2)'}, // Méret növelése hoovereléskor
              
            }}
          >
            <StorefrontOutlinedIcon sx={{ fontSize: '16px' }} />
          </Avatar>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-shop-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: '8px',
            width: '400px',
            maxHeight: '80vh',
            overflowY: 'auto',
            boxShadow: 24,
          }}
        >
          <Typography
            id="add-shop-modal"
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: '500',
              color: '#08979C',
              mb: 2,
            }}
          >
            Telephely hozzárendelése a számlához
          </Typography>
          <Grid2 container spacing={1} size={12}>
            {stores.map((shop) => (
              <Grid2 size={12} key={shop.id}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    width: '100%',
                    borderBottom: '1px solid #e0e0e0',
                    pb: 1,
                    pt: 1,
                    mb: 1,
                    borderRadius: 1,
                    transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 1,
                      bgcolor: '#F7F7F7'
                    },
                  }}
                  onClick={() => handleToggleShop(shop)} // Shop kiválasztása kattintásra
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', cursor: 'pointer', gap: 1 }}>
                    <Checkbox
                      checked={selectedShops.filter((s) => s.id === shop.id).length !== 0}
                      sx={{
                        color: '#08979C',
                        '&.Mui-checked': { color: '#08979C' },
                      }}
                    />
                    <Avatar sx={{ bgcolor: shop.color_code_2, width: 32, height: 32, fontSize: '10px' }}>
                      {shop.initials}
                    </Avatar>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: '500',
                          color: '#8C8C8C',
                        }}
                      >
                        {shop.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          fontWeight: '300',
                          color: '#8C8C8C',
                        }}
                      >
                        {shop.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid2>
            ))}
          </Grid2>
          <Button
            onClick={handleSave}
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              bgcolor: '#08979C',
              color: '#FFF',
              '&:hover': { bgcolor: '#68C9CC' },
            }}
          >
            Mentés
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AddShopAvatar;