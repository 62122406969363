import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

import BillsComment from './BillsComment';

const BillsCommentAccordionMenu = ({ billId, comment, openSubAccordion, handleSubAccordionChange, onRefresh }) => {
  return (
    <Accordion
      expanded={openSubAccordion[billId] === 'comments'}
      onChange={() => handleSubAccordionChange(billId, 'comments')}
      sx={{ boxShadow: 'none' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <CommentOutlinedIcon sx={{ fontSize: '18px', color: '#8c8c8c' }} />
          <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8c8c8c' }}>
            Megjegyzés hozzáadása
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <BillsComment
          billId={billId}
          existingComment={comment}
          onRefresh={onRefresh}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default BillsCommentAccordionMenu;