import { Badge, Box, Grid2, Typography } from '@mui/material';

import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

const BillsInfoIcons = ({ fileCount }) => {
  return (
    <Grid2 size={12}>
    <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-start', mt: 1}}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.6}}>
            <CloudDownloadOutlinedIcon sx={{ bgcolor: '#8c8c8c', padding: 0.3, borderRadius: 1, color: '#FFF', fontSize: '14px'}} />
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', color: '#8c8c8c', fontWeight: '300'}}>Nav online</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.6}}>
            <CreditScoreOutlinedIcon sx={{ bgcolor: '#8c8c8c', padding: 0.3, borderRadius: 1, color: '#FFF', fontSize: '14px'}} />
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', color: '#8c8c8c', fontWeight: '300'}}>Fizetve</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.6}}>
            <AssignmentTurnedInOutlinedIcon sx={{ bgcolor: '#8c8c8c', padding: 0.3, borderRadius: 1, color: '#FFF', fontSize: '14px'}} />
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', color: '#8c8c8c', fontWeight: '300'}}>Bevételezve</Typography>
        </Box>
        {fileCount > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.6 }}>
            <Badge
              badgeContent={fileCount}
              color="primary"
              sx={{
                '& .MuiBadge-badge': {
                  bgcolor: '#FF5722',
                  color: '#FFF',
                  fontSize: '7px',
                  fontWeight: '400',
                  minWidth: '16px',
                  height: '16px',
                  borderRadius: '50%',
                },
              }}
            >
              <AttachFileOutlinedIcon
                sx={{
                  bgcolor: '#8c8c8c',
                  padding: 0.3,
                  borderRadius: 1,
                  color: '#FFF',
                  fontSize: '14px',
                }}
              />
            </Badge>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', color: '#8c8c8c', fontWeight: '300' }}>
              Csatolmány
            </Typography>
          </Box>
        )}
    </Box>
  </Grid2>
  )
}

export default BillsInfoIcons
