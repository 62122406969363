import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Modal,
  Button,
  Grid2,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";

const ProdPics = () => {
  const [picture, setPicture] = useState(null); // Kezdeti üres állapot
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // Modal állapot

  // Kép hozzáadása vagy lecserélése
  const handleAddPicture = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newPicture = {
        id: Date.now(),
        src: URL.createObjectURL(file),
      };
      setPicture(newPicture);
    }
  };

  // Törlés megerősítése
  const handleDeleteConfirmation = () => {
    setPicture(null); // Kép törlése
    setDeleteModalOpen(false); // Modal bezárása
  };

  return (
    <Box sx={{ padding: 1, height: '100%', marginBottom: 3, }}>
      <Grid2
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center", // Vertikálisan középre
          justifyContent: "center", // Horizontálisan középre
          height: "100%", // Magasság biztosítása
        }}
      >
        {picture ? (
          <Grid2 size={{ xs: 8, md: 12 }}>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                overflow: "hidden",
                width: "100%",
                height: "200px",
              }}
            >
              <img
                src={picture.src}
                alt="Termék kép"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 1,
              }}
            >
              <IconButton
                onClick={() => setDeleteModalOpen(true)} // Modal megnyitása
                sx={{ color: "#888" }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid2>
        ) : (
          <Grid2 size={{ xs: 8, md: 12 }}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px dashed #ccc",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <label htmlFor="upload-button" style={{ cursor: "pointer" }}>
                <IconButton
                  component="span"
                  sx={{ fontSize: "32px", color: "#08979C" }}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
              <input
                id="upload-button"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleAddPicture}
              />
            </Box>
          </Grid2>
        )}
      </Grid2>

      {/* Törlés megerősítő modal */}
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Biztosan törlöd a képet?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setDeleteModalOpen(false)}
              sx={{ color: "#08979C", borderColor: "#08979C" }}
            >
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteConfirmation}
              sx={{ backgroundColor: "#ff0000", color: "#FFF" }}
            >
              Törlés
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProdPics;