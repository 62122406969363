import { useState, useEffect, useContext } from 'react';

import { DataContext } from '../../../../context/DataContext';

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid2, Grow } from '@mui/material';

import BillsMenus from './BillsMenus';
import BillsInfoIcons from './BillsInfoIcons';
import BillsHeader from './BillsHeader';
import BillsMainContent from './BillsMainContent';
import BillsDatesAndType from './BillsDateAndType';
import FileAttachedAccordionMenu from './FileAttachedAccordionMenu';
import BillsCommentAccordionMenu from './BillsCommentAccordionMenu';
import BillsLocationAccordionMenu from './BillsLocationAccordionMenu';

import { io } from "socket.io-client";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const BillsListElement = ({ bill, costplaces, page, onRefresh, onAccordion }) => {
  const {setMsg} = useContext(DataContext);

  const [invoiceCostplace, setInvoiceCostplace] = useState({});
  const [invoicePayment, setInvoicePayment] = useState({});
  const [invoiceStores, setInvoiceStores] = useState([]);

  const [fileCount, setFileCount] = useState(0);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [openSubAccordion, setOpenSubAccordion] = useState({}); // Sub Accordions (szülő szerint csoportosítva)

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getInvoiceCostplace();
    getPaymentMethod();
    getInvoiceStores();
  }, [bill]);

  useEffect(() => {
    socket.on("update_costplace", () => {
      getInvoiceCostplace();
    });

    socket.on("update_bill_stores", () => {
      getInvoiceStores();
    });
  }, [socket]);

  const getInvoiceCostplace = async() => {
    const res = await fetch(apiUrl+"/inbound/get/costplace/"+bill.id, {
        method: "GET"
    });

    const data = await res.json();

    if(!data.success){
        return setMsg({
            color: "error",
            message: data.message
        });
    }

    setInvoiceCostplace(data.costplace);
  };

  const getPaymentMethod = async() => {
    const res = await fetch(apiUrl+"/inbound/get/payment/"+bill.id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success){
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setInvoicePayment(data.payment);
  };

  const getInvoiceStores = async() => {
    const res = await fetch(apiUrl+"/inbound/get/stores/"+bill.id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success){
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setInvoiceStores(data.shops);
  };

  const handleFileChange = (count) => {
    setFileCount(count);
  };

  const handleSubAccordionChange = (parentId, subAccordionId) => {
    setOpenSubAccordion((prev) => ({
      ...prev,
      [parentId]: prev[parentId] === subAccordionId ? null : subAccordionId,
    }));
  };

  const handleAccordion = () => {
    setOpenSubAccordion(prev => ({
      ...prev,
      [bill.id]: null
    }));

    onAccordion();
  };

  return (
    <Grow in timeout={500}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1200px' }}>
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Accordion
              expanded={bill.open}
              onChange={handleAccordion}
              sx={{
                borderLeft: `6px solid ${invoiceCostplace?.costplace_color || '#FFF'}`,
              }}
            >
              <AccordionSummary>
                <Grid2 container size={12} spacing={1}>
                  <BillsHeader
                    bill={({
                      ...bill,
                      ...invoiceCostplace,
                      stores: [...invoiceStores]
                    })}
                    costplaces={costplaces}
                    onRefresh={onRefresh}
                    onSelectPlace={(place) => setSelectedPlace(place)}
                  />

                  <BillsMainContent bill={bill} />      

                  <BillsDatesAndType
                    bill={({
                      ...bill,
                      ...invoicePayment,
                      ...invoiceCostplace,
                      stores: [...invoiceStores]
                    })}
                    onRefresh={onRefresh}
                  />           

                  <BillsInfoIcons fileCount={fileCount} />
                </Grid2>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <BillsMenus
                    bill={({
                      ...bill,
                      ...invoicePayment,
                      ...invoiceCostplace,
                      stores: [...invoiceStores]
                    })}
                    page={page}
                    details={false}
                  />

                  <FileAttachedAccordionMenu
                    billId={bill.id}
                    fileCount={fileCount}
                    openSubAccordion={openSubAccordion}
                    handleSubAccordionChange={handleSubAccordionChange}
                    onFileChange={handleFileChange}
                  />

                  <BillsCommentAccordionMenu
                    billId={bill.id}
                    comment={bill.comment}
                    openSubAccordion={openSubAccordion}
                    handleSubAccordionChange={handleSubAccordionChange}
                    onRefresh={onRefresh}
                  />
                  <BillsLocationAccordionMenu
                    billId={bill.id}
                    openSubAccordion={openSubAccordion}
                    handleSubAccordionChange={handleSubAccordionChange}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid2>
        </Grid2>
      </Box>
    </Grow>
  );
};

export default BillsListElement;