import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const costCategories = [
  {
    category: 'Működési költségek',
    places: [
      { id: 1, name: 'Bérleti díj', color: '#8C8C8C' },
      { id: 10, name: 'Kommunikációs költségek', color: '#4CAF50' },
      { id: 11, name: 'Reprezentációs költségek', color: '#795548' },
      { id: 17, name: 'Irodaszerek', color: '#E91E63' },
      { id: 19, name: 'Tagsági díjak és egyesületi költségek', color: '#CDDC39' },
    ],
  },
  {
    category: 'Gyártási és beszerzési költségek',
    places: [
      { id: 3, name: 'Árubeszerzés', color: '#FFC107' },
      { id: 24, name: 'Gyártási költségek', color: '#FF9800' },
      { id: 21, name: 'Eszközbeszerzések és amortizáció', color: '#FF5722' },
    ],
  },
  {
    category: 'Adminisztratív költségek',
    places: [
      { id: 6, name: 'Ügyvédi és jogi költségek', color: '#9C27B0' },
      { id: 7, name: 'Könyvelési díjak', color: '#00BCD4' },
      { id: 20, name: 'Piackutatás és elemzések', color: '#3F51B5' },
    ],
  },
  {
    category: 'Értékesítési és promóciós költségek',
    places: [
      { id: 22, name: 'Hirdetési és promóciós költségek', color: '#9E9E9E' },
      { id: 25, name: 'Értékesítési költségek', color: '#795548' },
    ],
  },
];

const AddCostPlace = ({ onSelect }) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (place) => {
    setSelectedPlace(place);
    setOpen(false);
    if (onSelect) onSelect(place);
  };

  const handleClear = () => {
    setSelectedPlace(null);
    if (onSelect) onSelect(null);
  };

  const filteredCategories = costCategories.map((category) => ({
    ...category,
    places: category.places.filter((place) =>
      place.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
  }));

  return (
    <Box>
      {selectedPlace ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: selectedPlace.color,
            borderRadius: 1,
            gap: 0.5,
            paddingLeft: 0.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontWeight: '300',
              color: '#FFF',
            }}
          >
            {selectedPlace.name}
          </Typography>
          <IconButton size="small" onClick={handleClear}>
            <CloseIcon sx={{ fontSize: '12px', color: '#FFF' }} />
          </IconButton>
        </Box>
      ) : (
        <Box
          onClick={handleOpen}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px dashed #08979c',
            borderRadius: 1,
            padding: 0.4,
            gap: 1,
            cursor: 'pointer',
            '&:hover': { borderColor: '#68C9CC' },
          }}
        >
          <AddIcon sx={{ fontSize: '14px', color: '#08979c' }} />
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontWeight: '300',
              color: '#08979c',
            }}
          >
            Költséghely hozzáadás
          </Typography>
        </Box>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '400px',
            p: 3,
            bgcolor: 'background.paper',
            height: '100%',
            overflowY: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography sx={{ fontWeight: '600', color: '#08979C' }}>
              Költséghely hozzáadása
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: '18px'}}/>
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Keresés a költséghelyek között"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              const match = filteredCategories.find((category) =>
                category.places.some((place) =>
                  place.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
              );
              if (match) {
                setExpanded(match.category);
              } else {
                setExpanded(false);
              }
            }}
            sx={{ mb: 2 }}
          />
          {filteredCategories.map((category) => (
            <Accordion
              key={category.category}
              expanded={expanded === category.category}
              onChange={() =>
                setExpanded(expanded === category.category ? false : category.category)
              }
              sx={{
                mb: 1,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Typography sx={{ fontWeight: '600', color: '#8C8C8C' }}>
                    {category.category}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid2 container spacing={1}>
                  {category.places.map((place) => (
                    <Grid2 size={12} key={place.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => handleSelect(place)}
                          sx={{
                            justifyContent: 'flex-start',
                            textTransform: 'none',
                            fontSize: '12px',
                            fontWeight: '300',
                            color: '#FFF',
                            bgcolor: place.color,
                            '&:hover': { bgcolor: `${place.color}CC` },
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              alignItems: 'center',
                            }}
                          >
                            {place.name}
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                              }}
                            >
                              <IconButton size="small">
                                <DriveFileRenameOutlineOutlinedIcon
                                  sx={{ fontSize: '18px', color: '#FFF' }}
                                />
                              </IconButton>
                              <IconButton size="small">
                                <DeleteOutlineOutlinedIcon
                                  sx={{ fontSize: '18px', color: '#FFF' }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Button>
                      </Box>
                    </Grid2>
                  ))}
                </Grid2>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Drawer>
    </Box>
  );
};

export default AddCostPlace;