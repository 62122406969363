import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

const BillsLocationAccordionMenu = ({ billId, openSubAccordion, handleSubAccordionChange }) => {
  return (
    <Accordion
      expanded={openSubAccordion[billId] === 'registration'}
      onChange={() => handleSubAccordionChange(billId, 'registration')}
      sx={{ boxShadow: 'none' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <PushPinOutlinedIcon sx={{ fontSize: '18px', color: '#8c8c8c' }} />
          <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8c8c8c' }}>
            Iktatás
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails />
    </Accordion>
  );
};

export default BillsLocationAccordionMenu;