import { useState, useRef, useEffect, useContext } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Slider, TextField, Button, Grid2 } from "@mui/material";

const ProdPrice = ({ product, purchasePriceInit, sellPriceInit, vatRate, onUpdatePrice }) => {
  const {user, setMsg} = useContext(DataContext);

  // Állapotok az árakhoz és a módosításokhoz
  const [purchasePrice, setPurchasePrice] = useState(0); // Bruttó beszerzési ár
  const [sellPrice, setSellPrice] = useState(0); // Bruttó eladási ár

  const [tempPurchasePriceNet, setTempPurchasePriceNet] = useState(0); // Ideiglenes nettó beszerzési ár
  const [margin, setMargin] = useState(0); // Árrés százalék

  const [isModified, setIsModified] = useState(false); // Módosítás állapota

  // Eredeti értékek mentése referencia objektumba
  const originalValues = useRef({
    purchasePrice: purchasePriceInit,
    sellPrice: sellPriceInit,
    tempPurchasePriceNet: (purchasePriceInit / (1 + vatRate / 100)).toFixed(2),
    margin: Math.round(((sellPriceInit - purchasePriceInit) / purchasePriceInit) * 100),
  });

  const sellPriceNet = sellPrice / (1 + vatRate / 100);
  const netMargin = sellPriceNet - parseFloat(tempPurchasePriceNet);
  const grossMargin = sellPrice - purchasePrice;

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setPurchasePrice(Number(purchasePriceInit));
    setSellPrice(Number(sellPriceInit));
    setTempPurchasePriceNet(Number(purchasePriceInit / (1 + vatRate / 100)).toFixed(2));
    setMargin(Math.round(((sellPriceInit - purchasePriceInit) / purchasePriceInit) * 100));
  }, [purchasePriceInit, sellPriceInit, vatRate]);

  // Árrés százalék csúszka változtatása
  const handleSliderChange = (event, newValue) => {
    const newSellPrice = Math.round(purchasePrice * (1 + newValue / 100));

    setSellPrice(newSellPrice);
    setMargin(newValue);

    setIsModified(true);

    if(onUpdatePrice) {
      onUpdatePrice({ purchasePrice, sellPrice: newSellPrice });
    }
  };

  // Nettó beszerzési ár ideiglenes frissítése (onChange)
  const handleNetBuyingPriceChange = (event) => {
    setTempPurchasePriceNet(event.target.value); // Csak az ideiglenes érték frissítése
    setIsModified(true);
  };

  // Nettó beszerzési ár véglegesítése (onBlur)
  const handleNetBuyingPriceBlur = () => {
    const newNetPrice = parseFloat(tempPurchasePriceNet) || 0;
    const newGrossPrice = Math.round(newNetPrice * (1 + vatRate / 100));

    setPurchasePrice(newGrossPrice); // Bruttó beszerzési ár frissítése
    setMargin(Math.round(((sellPrice - newGrossPrice) / newGrossPrice) * 100)); // Árrés frissítése
    setIsModified(true);

    if(onUpdatePrice) {
      onUpdatePrice({ purchasePrice: newGrossPrice, sellPrice });
    }
  };

  // Bruttó beszerzési ár változás kezelése
  const handleBruttoBuyingPriceChange = (event) => {
    const newGrossPrice = parseFloat(event.target.value) || 0;
    setPurchasePrice(newGrossPrice);

    const newNetPrice = newGrossPrice / (1 + vatRate / 100);
    setTempPurchasePriceNet(newNetPrice.toFixed(2));
    setMargin(Math.round(((purchasePrice - newGrossPrice) / newGrossPrice) * 100)); // Árrés frissítése
    setIsModified(true);

    if(onUpdatePrice) {
      onUpdatePrice({ purchasePrice: newGrossPrice, sellPrice });
    }
  };

  // Eladási ár bruttó változás kezelése
  const handleSellingPriceChange = (event) => {
    const newPrice = parseFloat(event.target.value) || 0;
    setSellPrice(newPrice);
    setMargin(Math.round(((newPrice - purchasePrice) / purchasePrice) * 100)); // Árrés frissítése
    setIsModified(true);

    if(onUpdatePrice) {
      onUpdatePrice({ purchasePrice, sellPrice: newPrice });
    }
  };

  // Visszaállítás az eredeti értékekre
  const handleReset = () => {
    const { purchasePrice, sellPrice, tempPurchasePriceNet, margin } = originalValues.current;

    setPurchasePrice(purchasePrice);
    setSellPrice(sellPrice);
    setTempPurchasePriceNet(tempPurchasePriceNet);
    setMargin(margin);
    setIsModified(false);

    // Callback az eredeti értékek visszaállításához
    if(onUpdatePrice) {
      onUpdatePrice({
        purchasePrice,
        sellPrice,
      });
    }
  };

  // Mentés gomb
  const handleSave = async() => {
    const res = await fetch(apiUrl+"/product/price/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        barcode: product.barcode,
        company: user.company.id,
        price: sellPrice,
        user: user.id
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setIsModified(false);

    originalValues.current = {
      purchasePrice,
      sellPrice,
      tempPurchasePriceNet,
      margin,
    };
    
    onUpdatePrice();
  };

  // MarginColor meghatározása
  const marginColor = (() => {
    if (margin < 10) {
      return "#FF4D4F"; // Piros
    } else if (margin >= 10 && margin < 15) {
      return "#FF7A7A"; // Halvány piros
    } else if (margin >= 15 && margin < 20) {
      return "#FFA500"; // Narancssárga
    } else if (margin >= 20 && margin < 25) {
      return "#FFC069"; // Halvány narancssárga
    } else if (margin >= 25 && margin < 35) {
      return "#9ACD32"; // Halvány zöld
    } else {
      return "#08979C"; // Zöld
    }
  })();

  return (
    <Grid2 container spacing={3}>
      {/* Árrés Slider */}
      <Grid2 size={12}>
        <Slider
          value={margin}
          onChange={handleSliderChange} // Árrés csúszka frissítése
          min={0}
          max={100}
          step={1}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => `${value}%`}
          sx={{
            marginTop: 2,
            color: marginColor,
          }}
        />
      </Grid2>

      {/* Beszerzési ár Nettó */}
      <Grid2 size={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Beszerzési Ár Nettó"
          value={tempPurchasePriceNet}
          onChange={handleNetBuyingPriceChange} // Ideiglenes frissítés
          onBlur={handleNetBuyingPriceBlur} // Véglegesítés kikattintáskor
          inputProps={{ type: "number", min: 0 }}
          sx={{
            "& .MuiOutlinedInput-input": { color: "#08979C", fontSize: "14px", fontWeight: "500" },
          }}
        />
      </Grid2>

      {/* Beszerzési ár Bruttó */}
      <Grid2 size={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Beszerzési Ár Bruttó"
          value={purchasePrice}
          onChange={handleBruttoBuyingPriceChange}
          inputProps={{ type: "number", min: 0 }}
          sx={{
            "& .MuiOutlinedInput-input": { color: "#08979C", fontSize: "14px", fontWeight: "500" },
          }}
        />
      </Grid2>

      {/* Eladási ár Nettó */}
      <Grid2 size={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Eladási Ár Nettó"
          value={sellPriceNet.toFixed(2)}
          InputProps={{ readOnly: true }}
          sx={{
            "& .MuiOutlinedInput-input": { color: "#08979C", fontSize: "14px", fontWeight: "500" },
          }}
        />
      </Grid2>

      {/* Eladási Ár Bruttó */}
      <Grid2 size={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Eladási Ár Bruttó"
          value={sellPrice}
          onChange={handleSellingPriceChange}
          inputProps={{ type: "number", min: 0 }}
          sx={{
            "& .MuiOutlinedInput-input": { color: "#08979C", fontSize: "14px", fontWeight: "500" },
          }}
        />
      </Grid2>

      {/* Nettó Haszon */}
      <Grid2 size={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Nettó Haszon"
          value={netMargin.toFixed(2)}
          InputProps={{ readOnly: true }}
          sx={{
            "& .MuiOutlinedInput-input": { color: "#08979C", fontSize: "14px", fontWeight: "500" },
          }}
        />
      </Grid2>

      {/* Bruttó Haszon */}
      <Grid2 size={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Bruttó Haszon"
          value={grossMargin.toFixed(2)}
          InputProps={{ readOnly: true }}
          sx={{
            "& .MuiOutlinedInput-input": { color: "#08979C", fontSize: "14px", fontWeight: "500" },
          }}
        />
      </Grid2>

      {/* Visszaállítás gomb */}
      <Grid2 size={6}>
        <Button
          fullWidth
          variant="outlined"
          disabled={!isModified}
          onClick={handleReset}
          sx={{
            color: "#08979C",
            borderColor: "#08979C",
          }}
        >
          Visszaállítás
        </Button>
      </Grid2>

      {/* Mentés gomb */}
      <Grid2 size={6}>
        <Button
          fullWidth
          variant="contained"
          disabled={!isModified}
          onClick={handleSave}
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#08979C",
          }}
        >
          Mentés
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default ProdPrice;