import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { io } from "socket.io-client";

import { DataContext } from '../../../../context/DataContext';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Grid2,
    IconButton,
    Grow,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import BillsMenus from './BillsMenus';
import BillsHeader from './BillsHeader';
import BillsInfoIcons from './BillsInfoIcons';

import CurrencyText from '../../../../components/CurrencyText';

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const BillDetails = () => {
    const {user, setMsg} = useContext(DataContext);

    const [costplaces, setCostplaces] = useState([]);

    const [invoiceCostplace, setInvoiceCostplace] = useState({});
    const [invoicePayment, setInvoicePayment] = useState({});
    const [invoiceStores, setInvoiceStores] = useState([]);

    const [invoiceItems, setInvoiceItems] = useState([]);

    const [companyInfo, setCompanyInfo] = useState({});
    const [partnerInfo, setPartnerInfo] = useState({});

    const navigate = useNavigate();
    const pdfRef = useRef(); // PDF generálás referenciája

    const location = useLocation();
    const [bill, setBill] = useState(location.state.bill || {});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getCostplaces();

        getInvoiceCostplace();
        getPaymentMethod();
        getInvoiceStores();

        getCompanyInfo();
        getInvoiceItemized();
    }, []);

    useEffect(() => {
        socket.on("update_costplace", () => {
            getInvoiceCostplace();
            getCostplaces();
        });
    }, [socket]);

    const getCostplaces = async() => {
        const res = await fetch(apiUrl+"/inbound/costplaces/"+user.company.id, {
            method: "GET"
        });
    
        const data = await res.json();
    
        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    
        setCostplaces(data.costplaces);
    };

    const getInvoiceItemized = async() => {
        const res = await fetch(apiUrl+"/inbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: user.company.nav_token,
                invoiceNum: bill.serial_num
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceItems(data.invoice);

        if(data.fromNav) {
            saveInvoice(data.invoice);
        }
    };

    const getInvoice = async() => {
        const res = await fetch(apiUrl+"/inbound/get/serial", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serialNum: bill.serial_num })
        });
    
        const data = await res.json();
    
        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    
        setBill(data.invoice);
    };

    const getInvoiceCostplace = async() => {
        const res = await fetch(apiUrl+"/inbound/get/costplace/"+bill.id, {
            method: "GET"
        });
    
        const data = await res.json();
    
        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    
        setInvoiceCostplace(data.costplace);
    };
    
    const getPaymentMethod = async() => {
        const res = await fetch(apiUrl+"/inbound/get/payment/"+bill.id, {
            method: "GET"
        });
    
        const data = await res.json();
    
        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    
        setInvoicePayment(data.payment);
    };
    
    const getInvoiceStores = async() => {
        const res = await fetch(apiUrl+"/inbound/get/stores/"+bill.id, {
            method: "GET"
        });
    
        const data = await res.json();
    
        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    
        setInvoiceStores(data.shops);
    };

    const getCompanyInfo = async() => {
        const res = await fetch(apiUrl+"/company/"+bill.company, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCompanyInfo(data.company);

        getPartnerInfo();
    };

    const getPartnerInfo = async() => {
        const res = await fetch(apiUrl+"/partner/"+bill.partner, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPartnerInfo(data.partner);
    };

    const saveInvoice = async(invoiceData) => {
        const res = await fetch(apiUrl+"/inbound/itemized/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serialNum: bill.serial_num,
                itemized: invoiceData
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    };

    const generatePDF = async () => {
        const input = pdfRef.current;
    
        try {
            const canvas = await html2canvas(input, { scale: 2 }); // Nagyobb felbontás a jobb minőséghez
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4'); // A4 méretű oldal, álló tájolás
    
            const imgWidth = 190; // Kép szélessége a PDF-en (A4-es lap szélessége - margók)
            const pageHeight = 297; // A4-es lap magassága mm-ben
            const footerHeight = 30; // Oldalszám helye (mm-ben)
            const availableHeight = pageHeight - footerHeight; // Tartalom számára elérhető magasság
    
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Kép arányos magassága
            let position = 0; // Kezdő pozíció
            let pageCount = 1; // Oldalszámozás
    
            while (position < imgHeight) {
                const isLastPage = position + availableHeight >= imgHeight;
    
                // Az utolsó oldal magasságát helyesen számoljuk ki
                const canvasSliceHeight = isLastPage
                    ? canvas.height - position * (canvas.height / imgHeight)
                    : availableHeight * (canvas.height / imgHeight);
    
                const canvasSlice = document.createElement('canvas');
                canvasSlice.width = canvas.width;
                canvasSlice.height = canvasSliceHeight;
    
                const ctx = canvasSlice.getContext('2d');
                ctx.drawImage(
                    canvas,
                    0,
                    position * (canvas.height / imgHeight),
                    canvas.width,
                    canvasSliceHeight,
                    0,
                    0,
                    canvas.width,
                    canvasSliceHeight
                );
    
                const sliceImgData = canvasSlice.toDataURL('image/png');
                if (pageCount > 1) pdf.addPage();
                
                // Ha utolsó oldal, a magasságot dinamikusan számoljuk
                const sliceHeight = isLastPage
                    ? (canvasSliceHeight * imgWidth) / canvas.width
                    : availableHeight;
    
                pdf.addImage(sliceImgData, 'PNG', 10, 10, imgWidth, sliceHeight);
                pdf.text(`${pageCount}`, 105, 290, { align: 'center' }); // Oldalszám középre
    
                position += availableHeight; // Következő oldal kezdőpozíciója
                pageCount++;
            }
    
            pdf.save('BillDetails.pdf');
        } catch (error) {
            console.error('PDF generálás hiba:', error);
        }
    };    
      

    const calcNetSum = () => {
        return Math.round(invoiceItems.reduce((acc, obj) => {
            return acc += (obj.unitPrice*obj.quantity);
        }, 0));
    };

    const calcVatSum = () => {
        return Math.round(invoiceItems.reduce((acc, obj) => {
            return acc += ((obj.unitPrice*obj.quantity)*obj.vat);
        }, 0));
    };

    const calcGrossSum = () => {
        return Math.round(invoiceItems.reduce((acc, obj) => {
            return acc += ((obj.unitPrice*obj.quantity)*(obj.vat+1));
        }, 0));
    };

  return (
    <Grow in timeout={800}>
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8, alignItems: 'center', width: '100%', p: 2, boxSizing: 'border-box', gap: 2 }}>
        <Grid2 container spacing={1} sx={{ width: '100%', maxWidth: '1200px'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton onClick={() => navigate('/bills', {state: {bill: bill.id, page: location.state.page}})}><ArrowBackIcon /></IconButton>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c'}}>Vissza a számlákhoz</Typography>
            </Box>
            <Grid2 component={Paper} size={12} sx={{ p:1}}>
                <BillsMenus
                    details={true}
                    onPrintClick={generatePDF}
                />
            </Grid2>
            <Grid2 component={Paper} size={12} sx={{ p:1}}>
                <BillsHeader
                    bill={({
                        ...bill,
                        ...invoiceCostplace,
                        stores: invoiceStores.length !== 0 ? [...invoiceStores] : []
                    })}
                    costplaces={costplaces}
                    onRefresh={() => {
                        getInvoice();
                        getInvoiceCostplace();
                        getPaymentMethod();
                        getInvoiceStores();
                    }}          
                />
            </Grid2>
            <div ref={pdfRef} style={{ width: '100%' }}> {/* PDF generálás tartalma */}
                <Grid2 container spacing={1}>
                    <Grid2 component={Paper} size={{ xs:12, md: 6 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, width:'100%', p:2 }}>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8c8c8c'}}>Szállító</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8c8c8c'}}>{partnerInfo.name}</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#8c8c8c'}}>{partnerInfo.address}</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#8c8c8c'}}>{partnerInfo.bank_account}</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#8c8c8c'}}>{partnerInfo.tax_number}</Typography>
                        </Box>
                    </Grid2>
                    <Grid2 component={Paper} size={{ xs: 12, md: 6 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, width:'100%', p:2 }}>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8c8c8c'}}>Vevő</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8c8c8c'}}>{companyInfo.name}</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#8c8c8c'}}>{companyInfo.address}</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#8c8c8c'}}>{companyInfo.bank_account}</Typography>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#8c8c8c'}}>{companyInfo.tax_number}</Typography>
                        </Box>
                    </Grid2>
                    <Grid2 component={Paper} size={12} sx={{ p:1}}>
                        <BillsInfoIcons></BillsInfoIcons>
                    </Grid2>
                    <Grid2 size={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', p:1, backgroundColor: '#08979C', borderRadius: 1 }}>
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '600', color: '#FFF' }}>{bill.serial_num}</Typography>
                        </Box>
                    </Grid2>
                </Grid2>
                <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2, maxWidth: '1200px', mt: 1 }}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell sx={{ position: 'sticky', left: 0, backgroundColor: '#FFF', zIndex: 1, fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>#</TableCell>
                        <TableCell sx={{ position: 'sticky', left: 0, backgroundColor: '#FFF', zIndex: 1, fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Megnevezés</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Mennyiség</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Nettó/db</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Bruttó/db</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Áfa</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Áfa total</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Nettó total</TableCell>
                        <TableCell sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500',color: '#08979C' }}>Bruttó total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoiceItems.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell 
                                    sx={{
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: '#FFF',
                                        zIndex: 1,
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    {index + 1}
                                </TableCell>

                                <TableCell
                                    sx={{
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: '#FFF',
                                        zIndex: 1,
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    {item.itemName}
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    {item.quantity} {item.unit}
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    <CurrencyText val={Number(item.unitPrice).toFixed(2)} /> Ft
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    <CurrencyText val={Math.round((item.vat+1)*item.unitPrice)} /> Ft
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    {item.vat*100} %
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    <CurrencyText val={Math.round((item.vat*item.unitPrice)*item.quantity)} /> Ft
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    <CurrencyText val={Math.round(item.quantity*item.unitPrice)} /> Ft
                                </TableCell>

                                <TableCell
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        color: '#8c8c8c'
                                    }}
                                >
                                    <CurrencyText val={Math.round((item.unitPrice*(item.vat+1))*item.quantity)} /> Ft
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>      
                {/* Összegző rész */}
                <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    p: 1,
                    mt: 2,
                    backgroundColor: '#08979C',
                    borderRadius: 2,
                    maxWidth: '1200px',
                    gap: 2,
                    mb: 8,
                }}
                >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#FFF' }}>
                        Áfa Total: 
                    </Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '600', color: '#FFF' }}>
                        <CurrencyText val={calcVatSum()} /> Ft
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#FFF' }}>
                        Nettó Total: 
                    </Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '600', color: '#FFF' }}>
                        <CurrencyText val={calcNetSum()} /> Ft
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#FFF' }}>
                        Bruttó Total: 
                    </Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '600', color: '#FFF' }}>
                        <CurrencyText val={calcGrossSum()} /> Ft
                    </Typography>
                </Box>
                </Box>
            </div>
        </Grid2>
    </Box>
    </Grow>
  );
};

export default BillDetails;