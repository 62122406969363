import { useContext, useEffect, useState } from 'react';

import { DataContext } from '../../../../context/DataContext';

import {
  Box,
  Typography,
  IconButton,
  Drawer,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2,
  useMediaQuery,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import NoResults from './NoResults';
import NewCostPlace from './NewCostPlace';
import ModifyCostCategory from './ModifyCostCategory';
import ModifyCostPlace from './ModifyCostPlace';

const AddCostPlace = ({ bill, costplaces, onRefresh, onSelect }) => {
  const {user, setMsg} = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const [newDrawerOpen, setNewDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [costCategories, setCostCategories] = useState(costplaces);

  const [modifyCategoryOpen, setModifyCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [modifyDrawerOpen, setModifyDrawerOpen] = useState(false);
  const [selectedPlaceForModification, setSelectedPlaceForModification] = useState(null);

  const normalizeString = (str) =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''); // Ékezetek eltávolítása

  const [filteredCategories, setFilteredCategories] = useState(costCategories.map((category) => ({
    ...category,
    costplaces: category.costplaces.filter((place) =>
      normalizeString(place.name).includes(normalizeString(searchTerm))
    ),
  })));

  const [hasResults, setHasResults] = useState(filteredCategories.some(
    (category) => category.costplaces.length > 0
  ));

  const isMobile = useMediaQuery('(max-width:600px)');

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setCostCategories(costplaces);

    setFilteredCategories(costplaces.map((category) => ({
      ...category,
      costplaces: category.costplaces.filter((place) =>
        normalizeString(place.name).includes(normalizeString(searchTerm))
      ),
    })));
  }, [costplaces]);

  useEffect(() => {
    setHasResults(filteredCategories.some(
      (category) => category.costplaces.length > 0
    ));
  }, [filteredCategories]);

  useEffect(() => {
    setFilteredCategories(costCategories.map((category) => ({
      ...category,
      costplaces: category.costplaces.filter((place) =>
        normalizeString(place.name).includes(normalizeString(searchTerm))
      ),
    })));
  }, [searchTerm]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSearchTerm('');
    setExpanded(false);
  };

  const handleSelect = async(place) => {
    const res = await fetch(apiUrl+"/inbound/set", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "costplace",
        invoice: bill,
        user: user.id,
        costplace: place.costplaceId
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setOpen(false); // Bezárja a fő Drawert
    setSearchTerm(''); // Keresési mezőt alaphelyzetbe állítja
    setExpanded(false); // Bezárja az összes accordiont

    onRefresh();
  };

  const handleRemoveCostplace = async() => {
    const res = await fetch(apiUrl+"/inbound/tag/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "costplace",
        invoice: bill
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    onRefresh();
  };

  const handleNewDrawerOpen = () => setNewDrawerOpen(true);

  const handleModify = (place) => {
    setSelectedPlaceForModification(place);
    setModifyDrawerOpen(true);
  };

  const handleSearch = (e) => {
    const term = normalizeString(e.target.value);
    setSearchTerm(e.target.value); // Az eredeti érték megmarad

    if (!term) {
      setExpanded(false); // Ha a keresési mező üres, zárjuk be az összes Accordiont
      return;
    }

    const match = costCategories.find((category) =>
      category.costplaces.some((place) =>
        normalizeString(place.name).includes(term)
      )
    );

    if(match) {
      setExpanded(match.groupName); // Csak a megtalált kategória marad nyitva
    }
    else {
      setExpanded(false); // Ha nincs találat, zárjuk be az összeset
    }
  };

  return (
    <Box>
      {bill?.costplace ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: bill.costplace_color,
            borderRadius: 1,
            gap: 0.5,
            paddingLeft: 0.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontWeight: '300',
              color: '#FFF',
            }}
          >
            {bill.costplace_name}
          </Typography>
          <IconButton
            size="small"
            onClick={handleRemoveCostplace}
          >
            <CloseIcon sx={{ fontSize: '12px', color: '#FFF' }} />
          </IconButton>
        </Box>
      ) : (
      <Box
        onClick={handleOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px dashed #08979c',
          borderRadius: 1,
          padding: 0.4,
          gap: 1,
          cursor: 'pointer',
          transition: 'transform 0.2s ease, background-color 0.2s ease', // Átmeneti animáció
          '&:hover': {
            borderColor: '#68C9CC',
            border: '1px solid #08979C',
            backgroundColor: '#CBEEEF',
            transform: 'scale(1.1)', // Méret növelése hoovereléskor
          },
        }}
      >
          <AddIcon sx={{ fontSize: '14px', color: '#08979c' }} />
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontWeight: '300',
              color: '#08979c',
            }}
          >
            Költséghely
          </Typography>
        </Box>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : '400px',
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            bgcolor: 'background.paper',
            height: '100%',
            overflowY: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography sx={{ fontWeight: '600', color: '#08979C' }}>
              Költséghely hozzáadása
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Keresés a költséghelyek között"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            inputRef={(input) => {
              if (open && input) input.focus(); // Automatikusan fókuszál, amikor a drawer megnyílik
            }}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d9d9d9', // Alapértelmezett border szín
                },
                '&:hover fieldset': {
                  borderColor: '#08979C', // Hover szín
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#08979C', // Aktív/fókusz border szín
                },
              },
              '& .MuiInputLabel-root': {
                color: '#8C8C8C', // Alapértelmezett label szín
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#08979C', // Aktív/fókusz label szín
              },
            }}
          />
          {hasResults ? (
            filteredCategories.map((category) => (
              <Accordion
                key={category.groupId}
                expanded={expanded === category.groupName}
                onChange={() =>
                  setExpanded(
                    expanded === category.groupName ? false : category.groupName
                  )
                }
                sx={{
                  mb: 1,
                  boxShadow: 'none',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    '&:hover .icon-hover': { display: 'flex' }, // Hover esetén megjelenik az IconButton
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      height: '30px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-block',
                        width: 10,
                        height: 10,
                        backgroundColor: category.color,
                        marginRight: 1,
                        borderRadius: '2px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#8C8C8C',
                      }}
                    >
                      {category.groupName}
                    </Typography>
                    <IconButton
                      className="icon-hover"
                      onClick={() => {
                        setSelectedCategory(category);
                        setModifyCategoryOpen(true);
                      }}
                      sx={{
                        display: { xs: 'flex', sm: 'flex', md: 'none' },
                        marginLeft: 'auto',
                        color: '#8C8C8C',
                        '&:hover': { color: '#08979C' },
                        '@media (min-width:960px)': {
                          display: 'none',
                        },
                      }}
                    >
                      <DriveFileRenameOutlineOutlinedIcon
                        sx={{ fontSize: '18px' }}
                      />
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 container spacing={1}>
                    {category.costplaces.map((place) => (
                      <Grid2 size={12} key={place.id}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            position: 'relative',
                            '&:hover .icon-hover': { display: 'flex' }, // Asztali nézetben hover esetén megjelenítés
                          }}
                        >
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleSelect(place)}
                            sx={{
                              justifyContent: 'flex-start',
                              textTransform: 'none',
                              fontSize: '12px',
                              fontWeight: '300',
                              color: '#FFF',
                              bgcolor: category.color,
                              '&:hover': {
                                bgcolor: `${category.color}CC`,
                              },
                            }}
                          >
                            {place.name}
                          </Button>
                          <IconButton
                            className="icon-hover"
                            onClick={() => handleModify(place)}
                            sx={{
                              display: { xs: 'flex', sm: 'flex', md: 'none' }, // Mobilon és táblagépen mindig látható
                              position: 'absolute',
                              right: 10,
                              color: '#FFF',
                              padding: 1,
                              '&:hover': { color: '#08979C' },
                              '@media (min-width:960px)': {
                                display: 'none', // Asztali nézetben alapértelmezettként rejtett
                              },
                            }}
                          >
                            <DriveFileRenameOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      </Grid2>
                    ))}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <NoResults
              searchTerm={searchTerm}
              onCreateNew={handleNewDrawerOpen}
            />
          )}
        </Box>
      </Drawer>

      <NewCostPlace
        bill={bill}
        open={newDrawerOpen}
        onClose={() => {
          setNewDrawerOpen(false);
          setOpen(false);
          setSearchTerm('');
        }}
        onRefresh={onRefresh}
        costCategories={costCategories}
        setCostCategories={setCostCategories}
        setExpanded={setExpanded}
        onSelect={onSelect} // Hozzáadva
        searchTerm={searchTerm}
      />
      <ModifyCostPlace
        open={modifyDrawerOpen}
        onClose={() => {
          setModifyDrawerOpen(false);
          setSelectedPlaceForModification(null);
        }}
        costCategories={costCategories}
        selectedPlace={selectedPlaceForModification}
      />
      <ModifyCostCategory
        open={modifyCategoryOpen}
        selectedCategory={selectedCategory}
        onClose={() => setModifyCategoryOpen(false)}
      />
    </Box>
  );
};

export default AddCostPlace;