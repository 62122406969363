import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";

import ProductDetails from "./ProductDetails";
import ProdHeadLine from "./ProdHeadLine";

const ProductElement = ({ product, stock, onRefresh }) => {
  const {setMsg} = useContext(DataContext);

  const [productData, setProductData] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getProductPage();
  }, [product]);

  const getProductPage = async() => {
    const purchaseRes = await fetch(apiUrl+"/product/price/last", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        product: product.product_id
      })
    });

    const purchaseData = await purchaseRes.json();

    if(!purchaseData.success) {
      return setMsg({
        color: "error",
        message: purchaseData.message
      });
    }

    const sellRes = await fetch(apiUrl+"/product/sell/price/"+product.product_id, {
      method: "GET"
    });

    const sellData = await sellRes.json();

    if(!sellData.success) {
      return setMsg({
        color: "error",
        message: sellData.message
      });
    }

    setProductData({
      ...product,
      purchasePrice: purchaseData.income.purchase_price,
      sellPrice: sellData.sellPrice
    });
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', marginBottom: 1.5 }}>
      <Accordion
        sx={{
          width: "100%", // Teljes szélesség a szülő konténeren belül
          borderRadius: 1,
        }}
      >
        <AccordionSummary sx={{ padding: 0, margin: 0 }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <ProdHeadLine 
              product={productData}
            />
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {/* Termék adatlap */}
          <ProductDetails 
            product={productData} 
            stock={stock}
            onUpdatePrice={getProductPage}
            onRefresh={onRefresh}
          />
        </AccordionDetails>

      </Accordion>
    </Box>
  );
};

export default ProductElement;