import { Box, Typography, Avatar, Grid2 } from "@mui/material";

const ProdStockInfo = ({ stock }) => {
  return (
    <Box>
      <Grid2 container spacing={1}>
        {stock.map((item) => {
          return (
            <Grid2
              key={item.id}
              size={{ xs: 12, md: 2 }}
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              {/* Telephely avatár */}
              <Avatar
                sx={{
                  bgcolor: item.color_code_2,
                  width: 40,
                  height: 40,
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {item.name[0]} {/* Az első betű a telephely nevéből */}
              </Avatar>

              {/* Telephely és készlet adatok */}
              <Box sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#333",
                  }}
                >
                  {item.name} {/* Telephely neve */}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#888",
                  }}
                >
                  {item.quantity} db {/* Készlet darabszám */}
                </Typography>
              </Box>
            </Grid2>
          );
        })}
      </Grid2>
    </Box>
  );
};

export default ProdStockInfo;