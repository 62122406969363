import React from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const ProdSort = ({ sortConfig, handleSort }) => {
  const sortOptions = [
    { key: "margin", label: "Árrés" },
    { key: "name", label: "Megnevezés" },
    { key: "price", label: "Ár" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        mb: 2,
        gap: 1,
      }}
    >
      {sortOptions.map((option) => (
        <Box
          key={option.key}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            padding: 1,
            borderRadius: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: "300",
              color: "#888",
            }}
          >
            {option.label}
          </Typography>
          <Tooltip title="Növekvő sorrend">
            <IconButton
              size="small"
              onClick={() => handleSort(option.key, "asc")}
              sx={{
                backgroundColor:
                  sortConfig.key === option.key && sortConfig.order === "asc"
                    ? "#08979C"
                    : "transparent",
                color:
                  sortConfig.key === option.key && sortConfig.order === "asc"
                    ? "#FFF"
                    : "#888",
                "&:hover": {
                  backgroundColor: "#067a7a",
                  color: '#FFF'
                },
              }}
            >
              <ArrowDropUpIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Csökkenő sorrend">
            <IconButton
              size="small"
              onClick={() => handleSort(option.key, "desc")}
              sx={{
                backgroundColor:
                  sortConfig.key === option.key && sortConfig.order === "desc"
                    ? "#08979C"
                    : "transparent",
                color:
                  sortConfig.key === option.key && sortConfig.order === "desc"
                    ? "#FFF"
                    : "#888",
                "&:hover": {
                  backgroundColor: "#067a7a",
                  color: '#FFF'
                },
              }}
            >
              <ArrowDropDownIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
};

export default ProdSort;