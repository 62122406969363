import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Typography, Grid2, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ProdPics from "./ProdPics";
import ProdPrice from "./ProdPrice";
import ProdTags from "./ProdTags";
import ProdBaseInfoNew from "./ProdBaseInfoNew";
import ProdStockInfo from "./ProdStockInfo";
import ProdIncomingInfo from "./ProdIncomingInfo";
import ProdSalaryChart from "./ProdSalaryChart";

const ProductDetails = ({ product, stock, onUpdatePrice, onRefresh }) => {
  const {setMsg} = useContext(DataContext);

  const [editedProduct, setEditedProduct] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setEditedProduct(product);
  }, [product]);

  const handleSaveProduct = async() => {
    const res = await fetch(apiUrl+"/product/modify", {
      method: "POST",
      headers: {
       "Content-Type": "application/json"
      },
      body: JSON.stringify({ product: editedProduct })
    });

    const data = await res.json();

    if(!data.success) {
      console.log(data.message)
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    onRefresh();
  };

  const handleUpdateProduct = (updatedProduct) => {
    setEditedProduct(updatedProduct);
  };

  return (
    <Grid2 container spacing={0}>
      <Accordion sx={{ boxShadow: 0, padding: 0, width: '100%' }}>
        <AccordionSummary sx={{ padding: 0 }}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "800",
              color: "#08979C",
              paddingBottom: 1,
            }}
          >
            Alapadatok
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid2 container spacing={2}>
            {/* Termék Tag-ek kezelése */}
            <Grid2 size={12}>
              <ProdTags 
                product={product}
              />
            </Grid2>

            {/* Termék képek kezelése */}
            <Grid2 size={{ xs: 12, md: 2 }}>
              <ProdPics />
            </Grid2>
            
            {/* Termék alapadat_2 */}
            <Grid2 size={{ xs: 12, md: 10}}>      
              <ProdBaseInfoNew
                product={editedProduct}
                onUpdateProduct={handleUpdateProduct}
                onSaveProduct={handleSaveProduct}
              />
            </Grid2>
          </Grid2>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 0, padding: 0, width: '100%' }}>
        <AccordionSummary  sx={{ padding: 0}}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "800",
              color: "#08979C",
              paddingBottom: 1,
            }}
          >
            Árak kezelése
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Árak kezelése */}
          <Grid2 size={{ xs: 12, md: 6 }}>
              <ProdPrice
                product={product}
                purchasePriceInit={product.purchasePrice}
                sellPriceInit={product.sellPrice}
                vatRate={product.vat}
                onUpdatePrice={onUpdatePrice}
              />
          </Grid2> 
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 0, padding: 0, width: '100%'}}>
        <AccordionSummary  sx={{ padding: 0}}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "800",
              color: "#08979C",
              paddingBottom: 1,
            }}
          >
            Készlet adatok
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {/* Készletadatok */}
          <Grid2 size={{ xs: 12, md: 12 }}>
              <ProdStockInfo
                stock={stock}
              />
          </Grid2> 
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 0, padding: 0, width: '100%'}}>
        <AccordionSummary  sx={{ padding: 0}}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "800",
              color: "#08979C",
              paddingBottom: 1,
            }}
          >
            Beszerzések
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {/* Beszerzések */}
          <Grid2 size={{ xs: 12, md: 12 }}>
              <ProdIncomingInfo
                productId={product.product_id} 
              />
          </Grid2> 
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 0, padding: 0, width: '100%' }}>
        <AccordionSummary sx={{ padding: 0 }}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "800",
              color: "#08979C",
              paddingBottom: 1,
            }}
          >
            Forgalmi adatok
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {/* Forgalmi adatok */}
          <Grid2 size={{ xs: 12, md: 12 }}>
            <ProdSalaryChart
              productId={product.id} 
            />
          </Grid2> 
        </AccordionDetails>
      </Accordion>

    </Grid2>
  );
};

export default ProductDetails;