import { Box, Grid2, Grow } from '@mui/material';

import AddCostPlace from './AddCostPlace';
import AddShopAvatar from './AddShopAvatar';

const BillsHeader = ({ bill, costplaces, onRefresh, onSelectPlace }) => {
  return (
    <Grid2 size={12}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          width: '100%',
        }}
      >
        <Grow in timeout={1500}>
          <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', alignItems: 'center' }}>
            <AddCostPlace
              bill={bill}
              costplaces={costplaces}
              onRefresh={onRefresh}
              onSelect={onSelectPlace}
            />
          </Box>
        </Grow>

        <Grow in timeout={1500}>
          <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', alignItems: 'center' }}>
            <AddShopAvatar bill={bill} />
          </Box>
        </Grow>
      </Box>
    </Grid2>
  );
};

export default BillsHeader;